import Script from 'next/script';
import '../styles/main.css';
import { appWithTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wrapper } from '../redux/store';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import sbjs from 'sourcebuster';
import Cookies from 'universal-cookie';
import {
  deviceDetect,
  deviceType,
  mobileModel,
  osName,
  mobileVendor,
  browserName
} from 'react-device-detect';

import {
  setUser,
  setEvent,
  setReferer,
  setWebview
} from '../redux/actions/appActions';
import { trackActivity } from '../new-lib/apis';
import { generateRandomId, getRequest } from '../helpers/common';
import {
  configureGA4,
  configureMixpanel,
  getTrackerClientId,
  identifyMoengage,
  identifyTiktokUser,
  logMixpanelPageView
} from '../new-lib/analytics';
import NextNProgress from 'nextjs-progressbar';
import Layout from '../new-components/layout';
import { ThemeProvider } from 'styled-components';
import { theme } from '../styles/theme';
import GlobalStyles from '../styles/global-styles';
import SearchProvider from '../features/search/context/SearchContext';
import { getElementsToHide, removeElements } from '../new-lib/webview';
import useMaintainCompareState from '../features/compare/hooks/MaintainCompareState';
import useMaintainFavoritesState from '../features/favorite/hooks/MaintainFavorites';
import { getUserFavorites } from '../helpers/favorites';
import { addAllWatchList } from '../redux/slices/favoriteSlice';
import { MoengageScript } from '../new-lib/MoengageScript';
import { TiktokPixelScript } from '../new-lib/TiktokPixel';

const GA4_ID = process.env.NEXT_PUBLIC_GA4_ID;
const LISTING_FB_ID = process.env.NEXT_PUBLIC_LISTING_FACEBOOK_APP_ID;
const PARTNERS_FB_ID = process.env.NEXT_PUBLIC_PARTNERS_FACEBOOK_APP_ID;

function MyApp({ Component, pageProps }: any) {
  const router = useRouter();
  const { asPath, query, locale } = router;
  const cookies = new Cookies();
  const [queryClient] = useState(() => new QueryClient());
  const dispatch = useDispatch<any>();
  const isMounted = useRef(false);
  const webview = useSelector((state: any) => state.app.webview);
  const schema = pageProps.schema;

  useMaintainCompareState();
  useMaintainFavoritesState();

  // Init sourcebuster and get user's favorites
  useEffect(() => {
    sbjs.init();
  }, []);

  useEffect(() => {
    dispatch(setReferer(cookies.get('rf')));
  }, [asPath]);

  useEffect(() => {
    if (cookies.get('clientID')) {
      getUserFavorites(locale, cookies.get('clientID')).then(favorites => {
        dispatch(addAllWatchList(favorites));
      });
    }
  }, [cookies.get('clientID')]);

  // Get GA4 | Mixpanel payload
  const getAnalyticsPayload = async (userId: string) => {
    const deviceDetails = {
      brand: mobileVendor,
      model: mobileModel,
      browserName: browserName,
      deviceType: deviceType
    };
    const customParams = {
      locale: locale,
      utm_source: sbjs.get.current.src,
      utm_medium: sbjs.get.current.mdm,
      utm_campaign: sbjs.get.current.cmp,
      user_tracking_id: userId ?? '',
      device_details: JSON.stringify(deviceDetails),
      OS: osName,
      platform: 'web'
    };

    return customParams;
  };

  //Configure GA4 and Mixpanel
  const configureAnalytics = async (
    userId: string,
    customParams: any,
    type: string
  ) => {
    if (type === 'ga4') {
      configureGA4(await customParams);
    } else {
      configureMixpanel(userId, await customParams);
    }
  };

  // Configure layout for 3rd party iFrame or WebView clients
  useEffect(() => {
    if (webview) {
      const hiddenElements = getElementsToHide(webview);

      removeElements(hiddenElements);
    } else {
      if (query.partners) {
        dispatch(setWebview('partners'));
      }
      if (query['hide-all']) {
        dispatch(setWebview('app'));
      }
    }
  }, [asPath]);
  // Track user's activity on mount
  useEffect(() => {
    // configure GA4 before userId is available
    const analyticsPayload = getAnalyticsPayload('');
    configureAnalytics('', analyticsPayload, 'ga4');

    const submitTrackingView = async () => {
      const clientId = await getTrackerClientId();
      cookies.set('clientID', clientId);

      try {
        localStorage.setItem('utf', ',');
        const res = await trackActivity(
          {
            client_id: clientId,
            client_data: {
              ...deviceDetect(window.navigator.userAgent)
            },
            sbjs: {
              ...sbjs.get,
              current: {
                ...sbjs.get.current,
                cmp: query.utm_campaign || sbjs.get.current.cmp,
                src: query.utm_source || sbjs.get.current.src,
                typ:
                  query.utm_campaign || query.utm_source
                    ? 'utm'
                    : sbjs.get.current.typ
              }
            },
            request: getRequest(query, asPath)
          },
          locale
        );
        dispatch(setUser(res.user_id));
        dispatch(setEvent(res.event_id));
        localStorage.setItem('user_id', res.user_id);
        localStorage.setItem('event_id', res.event_id);
        identifyMoengage(res.user_id, locale ? locale : 'en');
        identifyTiktokUser(res.user_id);

        if (res.event_id === null || res.event_id === undefined) {
          localStorage.setItem('utf', ':');
        } else {
          localStorage.setItem('utf', '.');
        }

        // Configure mixpanel after userId is available
        const analyticsPayload = getAnalyticsPayload(res.user_id);
        configureAnalytics(res.user_id, analyticsPayload, 'mixpanel');
      } catch (error) {
        localStorage.setItem('random_event_id', generateRandomId());
        localStorage.setItem('utf', '*');
      }
    };
    submitTrackingView();
  }, []);

  // Track user's activity on route change
  useEffect(() => {
    const submitTrackingView = async () => {
      const clientId = await getTrackerClientId();

      try {
        localStorage.setItem('utf', ',');
        const res = await trackActivity({
          client_id: clientId,
          request: getRequest(query, asPath)
        });

        dispatch(setUser(res.user_id));
        dispatch(setEvent(res.event_id));
        localStorage.setItem('event_id', res.event_id);
        localStorage.setItem('user_id', res.user_id);

        if (res.event_id === null || res.event_id === undefined) {
          localStorage.setItem('utf', ':');
        } else {
          localStorage.setItem('utf', '.');
        }
      } catch (error) {
        localStorage.setItem('random_event_id', generateRandomId());
        localStorage.setItem('utf', '.');
      }
    };
    if (isMounted.current) {
      logMixpanelPageView();
      submitTrackingView();
    } else {
      isMounted.current = true;
    }
  }, [asPath]);

  return (
    <ThemeProvider theme={theme}>
      {/* Global Site Code Pixel - Facebook Pixel */}
      <Script strategy="afterInteractive" id="facebook-pixel">
        {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
  
            fbq('init', ${
              asPath.includes('/nawy-partners') ? PARTNERS_FB_ID : LISTING_FB_ID
            });
            fbq('track', 'PageView');
          `}
      </Script>

      {/* Tiktok Pixel Code */}
      <TiktokPixelScript />
      <Script
        id="gtag"
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA4_ID}`}
      />
      <Script
        id="gtag"
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=AW-877812802`}
      />

      <Script id="gtag-script" strategy="afterInteractive">
        {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-877812802');
        `}
      </Script>
      <MoengageScript />

      {schema && (
        <Script
          id="schema"
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
        />
      )}

      <Script
        id="organization-schema"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': ['Organization', 'Corporation'],
            url: 'www.nawy.com',
            brand: { '@type': 'Brand', name: 'Nawy' },
            name: 'Nawy',
            contactPoint: {
              '@type': 'ContactPoint',
              areaServed: 'eg',
              availableLanguage: ['ar', 'en']
            },
            sameAs: [
              'https://www.facebook.com/nawyrealestate',
              'https://www.linkedin.com/company/nawyestate/mycompany/',
              'https://www.youtube.com/channel/UCAtydzjr9JcWM4UNXU1AXig',
              'https://www.instagram.com/nawyrealestate/',
              'https://x.com/nawyegypt'
            ]
          })
        }}
      />

      <GlobalStyles />
      <NextNProgress
        color={theme.light.colors.primaryBlue}
        height={3}
        options={{ easing: 'ease', speed: 200 }}
        showOnShallow={false}
      />
      <QueryClientProvider client={queryClient}>
        <SearchProvider filterOptions={JSON.parse(pageProps.filterOptions)}>
          <Layout {...pageProps}>
            <Component {...pageProps} />
          </Layout>
        </SearchProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
      {/*The container (DOM location) in which the react portal is injected */}
      <div id="portal" />
      <div itemScope itemType="https://schema.org/Organization">
        <meta itemProp="url" content="https://www.nawy.com/" />
      </div>
    </ThemeProvider>
  );
}

export default wrapper.withRedux(appWithTranslation(MyApp));
