import { useState, useEffect, ReactNode } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { useQuery } from 'react-query';
import { EntityTypes, FormTypes } from '../../../../new-lib/constants/common';
import {
  getAreas,
  getPropertyTypes,
  getSearchLocations
} from '../../../../new-lib/apis';
import { useContactForm } from '../../hooks/ContactForm';
import ContactFormPresentation from './ContactFormPresentation';
import { logEvent } from '../../../../new-lib/analytics';
import { useSelector } from 'react-redux';
import { trackEvent } from '../../../../new-lib/remarketing';

const SuccessModal = dynamic(() => import('../SuccessModal'), { ssr: false });

interface FormProps {
  formSource?: FormTypes;
  location?: {
    areaId?: string;
    developerId?: string;
    compoundId?: string;
    propertyId?: string;
  };
  isContactPage?: boolean;
  customEventName?: string;
  renderedInputs?: {
    name?: boolean;
    location?: boolean;
    mobileNumber?: boolean;
    message?: boolean;
    email?: boolean;
    buyerOrSeller?: boolean;
  };
  withLabels?: boolean;
  layout?: {
    header?: ReactNode;
    footer?: ReactNode;
  };
  onSubmit?: () => void;
  forecastFlag?: boolean;
  organizationId?: number;
  landingPageId?: number;
  compound?: {
    id: number;
    name: string;
    nawy_organization_id: number | undefined;
    area?: { id: string; name: string };
  };
}

export type ListItemType = {
  value: string;
  label: string;
  nawy_organization_id?: number | null;
};

const ContactFormContainer: React.FC<FormProps> = ({
  formSource,
  location,
  isContactPage,
  renderedInputs,
  withLabels,
  layout,
  onSubmit,
  customEventName,
  forecastFlag,
  organizationId,
  landingPageId,
  compound
}) => {
  const { locale, query, asPath } = useRouter();
  const [searchInput, setSearchInput] = useState(compound?.name || '');

  const { data: searchResults } = useQuery(
    ['compounds', locale, searchInput],
    () => getSearchLocations(searchInput, locale),
    { staleTime: Infinity }
  );

  const { data: propertyTypes } = useQuery(
    ['property_types', locale],
    () => getPropertyTypes(locale),
    { enabled: renderedInputs?.buyerOrSeller ?? false, staleTime: Infinity }
  );

  const { data: areas } = useQuery(
    ['areas', locale],
    () => getAreas(true, locale),
    { enabled: renderedInputs?.buyerOrSeller ?? false, staleTime: Infinity }
  );

  const [compoundsList, setCompoundsList] = useState<ListItemType[]>([]);
  const [areasList, setAreasList] = useState<ListItemType[]>([]);
  const [propertyTypesList, setPropertyTypesList] = useState<ListItemType[]>(
    []
  );

  const selectedCompound = useSelector(
    (state: any) => state.map.selectedCompound
  );

  const [successModal, setSuccessModal] = useState<boolean>(false);
  const eventId = useSelector((state: any) => state.app.eventId);

  const onFormSubmit = () => {
    if (onSubmit) {
      onSubmit();
    }

    setSuccessModal(true);

    setTimeout(() => {
      setSuccessModal(false);
    }, 3000);
    logEvent.contact(
      'form_general',
      customEventName
        ? customEventName
        : isContactPage
        ? 'ContactPage'
        : 'FormAtEndOfPage',
      eventId,
      {
        entity_type: EntityTypes.COMPOUND,
        ...(contactFormData.formik.values.compound.value && {
          cmpnd_id: parseInt(contactFormData.formik.values.compound.value),
          cmpnd_name: contactFormData.formik.values.compound.label
        }),
        ...((contactFormData.formik.values.area.value || compound?.area) && {
          area_id: parseInt(
            contactFormData.formik.values.area.value || compound?.area?.id!
          ),
          area_name:
            contactFormData.formik.values.area.label || compound?.area?.name
        }),
        ...(contactFormData.formik.values.compound &&
          forecastFlag && { forecast_available: forecastFlag }),
        org_id: organizationId ?? 1
      }
    );
    if (location?.compoundId != undefined || location?.propertyId != undefined)
      trackEvent.purchase({
        listingIds: [parseInt(location.compoundId || location.propertyId || '')]
      });
  };

  const getLocations = async () => {
    try {
      let compoundsList = searchResults
        .filter((compound: any) => compound.category === 'compound')
        .map((item: any) => ({
          value: item.id,
          label: item.name,
          nawy_organization_id: item.nawy_organization_id
        }));
      setCompoundsList(compoundsList);
    } catch (e) {}
  };

  const getAreasList = async () => {
    try {
      let areasList = areas.map((item: any) => ({
        value: item.id,
        label: item.name
      }));
      setAreasList(areasList);
    } catch (e) {}
  };

  const getPropertyTypesList = async () => {
    try {
      let propertyTypesList = propertyTypes.map((item: any) => ({
        value: item.id,
        label: item.name
      }));
      setPropertyTypesList(propertyTypesList);
    } catch (e) {}
  };
  const handleListItemSelection = (
    listName: string,
    selectedItem: ListItemType
  ) => {
    contactFormData.formik.setFieldValue(listName, selectedItem);
  };

  const handleCompoundSearchInput = (input: string) => {
    setSearchInput(input);
  };

  const handleBuyerOrSeller = (seller: boolean) => {
    contactFormData.formik.setFieldValue('seller', false);
    contactFormData.handleResetForm({
      compound: contactFormData.formik.values.compound,
      area: contactFormData.formik.values.area,
      seller: seller
    });
  };

  useEffect(() => {
    if (compound?.name) {
      setSearchInput(compound?.name);
    }

    if (asPath.includes('/sahel-map') && selectedCompound) {
      setSearchInput(selectedCompound?.name);
    }
  }, [compound?.name]);

  useEffect(() => {
    if (compound?.name && !contactFormData.formik.values.compound?.value) {
      const selectedCompound = compoundsList.find(
        (cmpd: ListItemType) => cmpd.label == compound?.name
      );

      contactFormData.formik.setFieldValue('compound', selectedCompound);

      if (renderedInputs?.buyerOrSeller && compound.area) {
        contactFormData.formik.setFieldValue('area', {
          label: compound.area.name,
          value: compound.area.id
        });
      }
    }
  }, [compoundsList, searchInput, compound?.name]);

  useEffect(() => {
    contactFormData.formik.setFieldValue('compound', '');
  }, [query.compound_slug]);

  useEffect(() => {
    if (!compoundsList) return;
    getLocations();
  }, [searchResults, asPath]);

  useEffect(() => {
    if (!areas) return;
    getAreasList();
  }, [areas]);

  useEffect(() => {
    if (!propertyTypes) return;
    getPropertyTypesList();
  }, [propertyTypes]);

  const contactFormData = useContactForm({
    onFormSubmit,
    formSource: formSource,
    location: location,
    landingPageId
  });

  return (
    <>
      <ContactFormPresentation
        {...contactFormData}
        handleCompoundSearchInput={handleCompoundSearchInput}
        handleListItemSelection={handleListItemSelection}
        handleBuyerOrSeller={handleBuyerOrSeller}
        compounds={compoundsList}
        areas={areasList}
        propertyTypes={propertyTypesList}
        renderedInputs={renderedInputs}
        withLabels={withLabels}
        layout={layout}
      />
      {successModal && <SuccessModal />}
    </>
  );
};

ContactFormContainer.defaultProps = {
  formSource: FormTypes.ORGANIC_FORM
};
export default ContactFormContainer;
