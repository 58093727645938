/* eslint-disable react-hooks/exhaustive-deps */
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { updateQuery } from '../../../../../helpers/common';
import { handleSelectOptionsWithMaxValue } from '../../../../../helpers/search-filters';
import {
  filterSingleSelectInputsTypes,
  InputOption
} from '../../../../../new-lib/types/search-filters';
import { SearchFiltersActionTypes } from '../../../../../reducers/search-filters/actions';
import {
  SearchContext,
  SearchContextInterface
} from '../../../context/SearchContext';
import { logEvent } from '../../../../../new-lib/analytics';
import { SearchFiltersTypes } from '../../../../../reducers/search-filters/reducer';

interface MultiSelectContainerPropsType {
  filterType: filterSingleSelectInputsTypes;
  shapeClass: string;
  Component: any;
  maxDisplayedValue?: number;
  isMoveNowFiltered?: boolean;
}
const SingleSelectContainer = ({
  filterType,
  Component,
  maxDisplayedValue,
  shapeClass,
  isMoveNowFiltered
}: MultiSelectContainerPropsType) => {
  const { filterOptions, searchFiltersState, searchFiltersDispatch } =
    useContext(SearchContext) as SearchContextInterface;
  const [newOptions, setNewOptions] = useState<InputOption[]>([]);
  const router = useRouter();
  useEffect(() => {
    handleSelectOptionsWithMaxValue(
      filterOptions[filterType],
      maxDisplayedValue,
      setNewOptions
    );
  }, [maxDisplayedValue]);

  const onChange = (values: string | number) => {
    searchFiltersDispatch({
      type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
      payload: {
        name: filterType,
        value: values
      }
    });

    logEvent.search(
      `filter_${filterType}`,
      'filtersMenu',
      'filter',
      searchFiltersState,
      { [filterType]: values }
    );

    if (!router.asPath.includes('/sahel-map')) {
      updateQuery(
        router,
        [
          { name: filterType, value: values.toString() },
          { name: SearchFiltersTypes.PAGE_NUMBER, value: 1 }
        ],
        undefined
      );

      if (values == '') {
        updateQuery(router, undefined, [filterType]);
      }
    }
  };
  return (
    <Component
      isMoveNowFiltered={isMoveNowFiltered}
      onChange={onChange}
      selectedValues={searchFiltersState.searchFilters[filterType]}
      options={newOptions}
      shapeClass={shapeClass}
    />
  );
};

export default SingleSelectContainer;
