import axios from 'axios';
import { objToQuery, getSearchBody } from '../lib/helpers';
import fetcher from './Wrappers/fetch';

let API_URL = process.env.NEXT_PUBLIC_API;
let HOST = process.env.NEXT_PUBLIC_HOST;
export const getCompoundPropertyTypesEndPoint = (language, compoundId) => {
  let clientId;
  ga(function (tracker) {
    clientId = tracker.get('clientId');
  });
  const query = {
    client_id: clientId,
    ...(language && { language }),
    count_only: true
  };
  return `${API_URL}/compounds/${compoundId}/get_available_property_types?${objToQuery(
    query
  )}`;
};
export const getFavoritesEndPoint = language => {
  let clientId;
  ga(function (tracker) {
    clientId = tracker.get('clientId');
  });
  const query = { client_id: clientId, ...(language && { language }) };
  return `${API_URL}/watchlists/get_favorites?${objToQuery(query)}`;
};
export const getCityscapeCompounds = language => {
  let clientId;
  ga(function (tracker) {
    clientId = tracker.get('clientId');
  });
  const query = { client_id: clientId, ...(language && { language }) };
  return `${API_URL}/compounds/with_offers?${objToQuery(query)}`;
};

export const getCityscapeSearch = language => {
  let clientId;
  ga(function (tracker) {
    clientId = tracker.get('clientId');
  });
  const query = { client_id: clientId, ...(language && { language }) };
  return `${API_URL}/compounds/search_offers?${objToQuery(query)}`;
};

export const getRecommandedSearch = (property_id, language) => {
  let client_id;
  ga(function (tracker) {
    client_id = tracker.get('clientId');
  });

  const query = { client_id, property_id, ...(language && { language }) };
  return `${API_URL}/properties/get_featuring?${objToQuery(query)}`;
};

export const getStatistics = language => {
  const query = { ...(language && { language }) };
  return fetcher(`${API_URL}/properties/get_statistics?${objToQuery(query)}`, {
    method: 'GET'
  }).then(response => response.json());
};

export const getProperties = (language, start, page_size) => {
  const query = { ...(language && { language }), start, page_size };
  return fetcher(`${API_URL}/properties?${objToQuery(query)}`, {
    method: 'GET'
  }).then(response => response.json());
};

export const getProperty = async (slug, language) => {
  const query = { slug, ...(language && { language }) };
  return await fetcher(`${API_URL}/properties?${objToQuery(query)}`, {
    method: 'GET'
  }).then(response => response.json());
};

export const getCompounds = (language, moveNowOnly) => {
  const query = { ...(language && { language }), move_now_only: moveNowOnly };
  return fetcher(`${API_URL}/compounds?${objToQuery(query)}`, {
    method: 'GET'
  }).then(response => response.json());
};

export const getCompoundsCached = (language, moveNowOnly) => {
  const query = { ...(language && { language, moveNowOnly }) };
  return fetch(`${HOST}/api/getCompoundsCached?${objToQuery(query)}`).then(
    response => response.json()
  );
  // const query = { ...(language && { language }) };
  // return fetcher(`${API_URL}/compounds?${objToQuery(query)}`, {
  //   method: "GET",
  // }).then((response) => response.json());
};

export const getCompoundsWithRange = (language, start, page_size) => {
  const query = { ...(language && { language }), start, page_size };
  return fetcher(
    `${API_URL}/compounds/get_compounds_with_range?${objToQuery(query)}`,
    {
      method: 'GET'
    }
  ).then(response => response.json());
};

export const getCompound = async (slug, language) => {
  const query = { slug, ...(language && { language }) };
  return await fetcher(`${API_URL}/compounds?${objToQuery(query)}`, {
    method: 'GET'
  }).then(response => response.json());
};

export const getCompoundCached = async (slug, language) => {
  const query = { slug, ...(language && { language }) };
  return fetch(`${HOST}/api/getCompoundCached?${objToQuery(query)}`).then(
    response => response.json()
  );
};

export const getCompoundPropertyTypes = async (language, offset, limit) => {
  const query = { ...(language && { language }), offset, limit };
  return fetcher(
    `${API_URL}/compounds/get_compound_property_types?${objToQuery(query)}`,
    {
      method: 'GET'
    }
  ).then(response => response.json());
};

export const getDevelopers = language => {
  const query = { ...(language && { language }) };
  return fetcher(`${API_URL}/developers?${objToQuery(query)}`, {
    method: 'GET'
  }).then(response => response.json());
};

export const getDevelopersCached = language => {
  const query = { ...(language && { language }) };
  return fetch(`${HOST}/api/getDevelopersCached?${objToQuery(query)}`).then(
    response => response.json()
  );
  // const query = { ...(language && { language }) };
  // return fetcher(`${API_URL}/developers?${objToQuery(query)}`, {
  //   method: "GET",
  // }).then((response) => response.json());
};

export const getDevelopersWithSummary = language => {
  const query = { with_summary: true, ...(language && { language }) };
  return fetcher(`${API_URL}/developers?${objToQuery(query)}`, {
    method: 'GET'
  }).then(response => response.json());
};

export const getDeveloper = (slug, language) => {
  const query = { slug, ...(language && { language }) };
  return fetcher(`${API_URL}/developers?${objToQuery(query)}`, {
    method: 'GET'
  }).then(response => response.json());
};

export const filterDevelopers = (area_id, language) => {
  const query = { area_id, ...(language && { language }) };
  return fetcher(`${API_URL}/developers?${objToQuery(query)}`, {
    method: 'GET'
  }).then(response => response.json());
};

export const getDeveloperCompounds = (id, language) => {
  const query = { id: id, ...(language && { language }) };
  return fetcher(
    `${API_URL}/developers/get_developer_compounds?${objToQuery(query)}`,
    {
      method: 'GET'
    }
  ).then(response => response.json());
};

export const getDeveloperCompoundsCached = async (id, language) => {
  const query = { id: id, ...(language && { language }) };
  return fetch(
    `${HOST}/api/getDeveloperCompoundsCached?${objToQuery(query)}`
  ).then(response => response.json());
};

export const getAreas = (language, moveNowOnly) => {
  const query = { ...(language && { language }), move_now_only: moveNowOnly };
  return fetcher(`${API_URL}/areas?${objToQuery(query)}`, {
    method: 'GET'
  }).then(response => response.json());
};

export const getAreasCached = (language, moveNowOnly) => {
  const query = { ...(language && { language, moveNowOnly }) };
  return fetch(`${HOST}/api/getAreasCached?${objToQuery(query)}`).then(
    response => response.json()
  );
  // const query = { ...(language && { language }) };
  // return fetcher(`${API_URL}/areas?${objToQuery(query)}`, {
  //   method: "GET",
  // }).then((response) => response.json());
};

export const getAreasWithSummary = language => {
  const query = { with_summary: true, ...(language && { language }) };
  return fetcher(`${API_URL}/areas?${objToQuery(query)}`, {
    method: 'GET'
  }).then(response => response.json());
};

export const getArea = (slug, language) => {
  const query = { name: slug, ...(language && { language }) };
  return fetcher(`${API_URL}/areas?${objToQuery(query)}`, {
    method: 'GET'
  }).then(response => response.json());
};

export const getAreaCompounds = (id, language) => {
  const query = { id: id, ...(language && { language }) };
  return fetcher(`${API_URL}/areas/get_area_compounds?${objToQuery(query)}`, {
    method: 'GET'
  }).then(response => response.json());
};

export const getAreaCompoundsCached = async (id, language) => {
  const query = { id: id, ...(language && { language }) };
  return fetch(`${HOST}/api/getAreaCompoundsCached?${objToQuery(query)}`).then(
    response => response.json()
  );
};

export const getPropertyTypes = language => {
  const query = { ...(language && { language }) };
  return fetcher(`${API_URL}/property_types?${objToQuery(query)}`, {
    method: 'GET'
  }).then(response => response.json());
};

export const getPropertyTypesCached = language => {
  const query = { ...(language && { language }) };
  return fetch(`${HOST}/api/getPropertyTypesCached?${objToQuery(query)}`).then(
    response => response.json()
  );
  // const query = { ...(language && { language }) };
  // return fetcher(`${API_URL}/property_types?${objToQuery(query)}`, {
  //   method: "GET",
  // }).then((response) => response.json());
};

export const getPropertyType = (query, language) => {
  query = { ...query, ...(language && { language }) };
  return fetcher(`${API_URL}/property_types?${objToQuery(query)}`, {
    method: 'GET'
  }).then(res => res.json());
};

export const getFeaturedProperties = (client_id, property_id, language) => {
  const query = { client_id, property_id, ...(language && { language }) };
  return fetcher(`${API_URL}/properties/get_featuring?${objToQuery(query)}`, {
    method: 'GET'
  }).then(response => response.json());
};

export const getSearchResults = async (body, language) => {
  if (typeof window === 'undefined') {
    const query = { ...(language && { language }) };
    const response = await fetcher(
      `${API_URL}/properties/search?${objToQuery(query)}`,
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(getSearchBody(body))
      }
    );
    return await response.json();
  }

  return new Promise((resolve, reject) => {
    let clientId;
    ga(function (tracker) {
      clientId = tracker.get('clientId');
      const query = { client_id: clientId, ...(language && { language }) };

      fetcher(`${API_URL}/properties/search?${objToQuery(query)}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(getSearchBody(body))
      }).then(response => {
        resolve(response.json());
      });
    });
  });
};

export const getSearchResultsServerSide = async (body, language, clientId) => {
  const query = { ...(language && { language }), client_id: clientId };
  body = getSearchBody(body);
  const URLl = `${API_URL}/properties/search?${objToQuery(query)}`;
  const { data } = await axios.post(URLl, body);
  return data;
};

export const getFooterLinks = language => {
  const query = { ...(language && { language }) };
  return fetcher(`${API_URL}/get_footer_links?${objToQuery(query)}`, {
    method: 'GET'
  }).then(response => response.json());
};

export const getFooterLinksCached = language => {
  const query = { ...(language && { language }) };
  return fetch(`${HOST}/api/getFooterLinksCached?${objToQuery(query)}`).then(
    response => response.json()
  );
};

export const searchCompounds = (body, language) => {
  const query = { ...(language && { language }) };
  return fetcher(`${API_URL}/compounds/search?${objToQuery(query)}`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify(body)
  }).then(response => response.json());
};

export const createSession = (body, language) => {
  const query = { ...(language && { language }) };
  return fetcher(`${API_URL}/sessions/create_session?${objToQuery(query)}`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  }).then(response => response.json());
};

export const checkSession = (body, language) => {
  const query = { ...(language && { language }) };
  return fetcher(`${API_URL}/sessions/check_session?${objToQuery(query)}`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  }).then(response => response.json());
};

export const getAvailablePropertyTypes = async (compound_id, language) => {
  const query = { ...(language && { language }) };
  return await fetcher(
    `${API_URL}/compounds/${compound_id}/get_available_property_types?${objToQuery(
      query
    )}`,
    {
      method: 'GET'
    }
  ).then(response => response.json());
};

export const getAvailablePropertyTypesCached = async (
  compound_id,
  language
) => {
  const query = { compound_id, ...(language && { language }) };
  return fetch(
    `${HOST}/api/getAvailablePropertyTypesCached?${objToQuery(query)}`
  ).then(response => response.json());
  // const query = { ...(language && { language }) };
  // return await fetcher(
  //   `${API_URL}/compounds/${compound_id}/get_available_property_types?${objToQuery(
  //     query
  //   )}`,
  //   {
  //     method: "GET",
  //   }
  // ).then((response) => response.json());
};

export const getAvailablePropertyTypesCount = async (compound_id, language) => {
  const query = { ...(language && { language }) };
  return await fetcher(
    `${API_URL}/compounds/${compound_id}/get_available_property_types?count_only=true&&${objToQuery(
      query
    )}`,
    {
      method: 'GET'
    }
  ).then(response => response.json());
};

export const getFilterOptions = language => {
  // const query = { ...(language && { language }) };
  // return fetcher(
  //   `${API_URL}/properties/get_filter_options?${objToQuery(query)}`,
  //   {
  //     method: "GET",
  //   }
  // ).then((response) => response.json());
  return new Promise((resolve, reject) => {
    const query = { ...(language && { language }) };
    fetcher(`${API_URL}/properties/get_filter_options?${objToQuery(query)}`, {
      method: 'GET'
    }).then(response => {
      resolve(response.json());
    });
  });
};

export const getFilterOptionsCached = language => {
  const query = { ...(language && { language }) };
  return fetch(`${HOST}/api/getFilterOptionsCached?${objToQuery(query)}`).then(
    response => response.json()
  );
  // return new Promise((resolve, reject) => {
  //   const query = { ...(language && { language }) };
  //   fetcher(`${API_URL}/properties/get_filter_options?${objToQuery(query)}`, {
  //     method: "GET",
  //   }).then((response) => {
  //     resolve(response.json());
  //   });
  // });
};

export const getFilterOption = (body, language) => {
  const query = { ...body, ...(language && { language }) };
  return fetcher(
    `${API_URL}/properties/get_filter_options?${objToQuery(query)}`,
    {
      method: 'GET'
    }
  ).then(response => response.json());
};

export const submitContactForm = (body, language) => {
  const query = { ...(language && { language }) };

  return fetcher(`${API_URL}/submit_form?${objToQuery(query)}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  });
};

export const submitPropertyMonitorForm = (body, language) => {
  const query = { ...(language && { language }) };

  return fetcher(`${API_URL}/property_monitor?${objToQuery(query)}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  });
};

export const getFavorites = async language => {
  return new Promise((resolve, reject) => {
    let clientId;
    ga(function (tracker) {
      clientId = tracker.get('clientId');
      const query = { client_id: clientId, ...(language && { language }) };
      fetcher(`${API_URL}/watchlists/get_favorites?${objToQuery(query)}`, {
        method: 'GET'
      }).then(response => {
        resolve(response.json());
      });
    });
  });
};

export const updateWatchList = async (action, body) => {
  if (action == 'unfollow') {
    action = '/unfollow';
  } else {
    action = '';
  }
  return await fetcher(`${API_URL}/watchlists${action}?`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  });
};

export const addToFavorites = async (id, type) => {
  let clientId;
  await ga(function (tracker) {
    clientId = tracker.get('clientId');
  });
  if (type === 'property') {
    let body = {
      client_id: clientId,
      category: 'favorite',
      property_id: id
    };
    return await updateWatchList('follow', body);
  } else {
    let body = {
      client_id: clientId,
      category: 'favorite',
      compound_id: id
    };
    return await updateWatchList('follow', body);
  }
};

export const removeFromFavorites = async (id, type) => {
  let clientId;
  await ga(function (tracker) {
    clientId = tracker.get('clientId');
  });
  if (type === 'property') {
    let body = {
      client_id: clientId,
      category: 'favorite',
      property_id: id
    };
    return await updateWatchList('unfollow', body);
  } else {
    let body = {
      client_id: clientId,
      category: 'favorite',
      compound_id: id
    };
    return await updateWatchList('unfollow', body);
  }
};

export const getLandingPage = async id => {
  return fetcher(`${API_URL}/landing_pages/${id}`, {
    method: 'GET'
  }).then(response => response.json());
};

export const getQuickSearches = async language => {
  const query = { ...(language && { language }) };
  return fetcher(`${API_URL}/quick_searches?${objToQuery(query)}`, {
    method: 'GET'
  }).then(response => response.json());
};

// export const trackActivity = (body) => {
//   let data = new FormData();
//   data.append("request", JSON.stringify(body.request));
//   data.append("client_id", body.client_id);
//   return navigator.sendBeacon(`${API_URL}/track_activity`, data);
// };

export const trackActivity = (body, language) => {
  const query = { ...(language && { language }) };

  return fetcher(`${API_URL}/track_activity?${objToQuery(query)}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  }).then(response => response.json());
};

export const createLead = async body => {
  const res = await fetch(`https://crmtestapi.cooingestate.com/api/leads`, {
    method: 'POST',
    headers: {
      Authorization:
        'eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjozMywiZXhwIjoxNjQ0NTkxNjM4fQ.jljipWVY-dIW9kTeEBOazwxvPpy-MHdRVRiwc0Y8XBk',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  });
  return res.json();
};

export const phoneLeadsInfo = (body, language) => {
  const query = { ...(language && { language }) };
  return fetcher(`${API_URL}/phone_leads_info?${objToQuery(query)}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  });
};
export const createSellerUnit = body => {
  return fetcher(`${API_URL}/create_seller_unit?`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  });
};

export const clickWhatsapp = (body, language) => {
  const query = { ...(language && { language }) };

  return fetcher(`${API_URL}/click_whatsapp?${objToQuery(query)}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  }).then(res => res.json());
};

export const reportError = async body => {
  const res = await fetch(`${API_URL}/report_error`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  });
  return res.json();
};

export const metaTagsScraper = async url => {
  const res = await fetch(`${HOST}/api/scraper?targetUrl=${url}`);
  return res.json();
};
