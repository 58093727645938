import { useRouter } from 'next/router';
import {
  SearchContextInterface,
  SearchContext
} from '../../../context/SearchContext';
import { SearchFiltersActionTypes } from '../../../../../reducers/search-filters/actions';
import { SearchFiltersTypes } from '../../../../../reducers/search-filters/reducer';
import { updateQuery } from '../../../../../helpers/common';
import { InputOption } from '../../../../../new-lib/types/search-filters';
import ToggleFilterPresentation from './ToggleFilterPresentation';
import { useContext } from 'react';
import { logEvent } from '../../../../../new-lib/analytics';

interface FilterProps {
  filterType: SearchFiltersTypes.CATEGORY;
  options: InputOption[];
}

const ToggleFilterContainer = ({
  filterType,
  options
}: FilterProps): JSX.Element => {
  const { searchFiltersDispatch, searchFiltersState } = useContext(
    SearchContext
  ) as SearchContextInterface;
  const router = useRouter();

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    searchFiltersDispatch({
      type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
      payload: {
        name: filterType,
        value: e.currentTarget.value
      }
    });
    logEvent.search(
      `filter_show_type`,
      'filtersMenu',
      'filter',
      searchFiltersState,
      { [filterType]: e.currentTarget.value }
    );
    updateQuery(
      router,
      [{ name: filterType, value: e.currentTarget.value }],
      []
    );
  };

  return (
    <ToggleFilterPresentation
      options={options}
      filterType={filterType}
      handleChange={handleChange}
    />
  );
};

export default ToggleFilterContainer;
