import {
  mapSearchFiltersKeysToSearchApiKeys,
  mapSearchFiltersValuesToSearchApiValues
} from '../../helpers/search-filters';
import { SearchFiltersActions, SearchFiltersActionTypes } from './actions';
import Router from 'next/router';

export enum SearchFiltersTypes {
  CATEGORY = 'category',
  COMPOUNDS = 'compounds',
  PAGE_NUMBER = 'page_number',
  AREAS = 'areas',
  DEVELOPERS = 'developers',
  PROPERTY_TYPES = 'property_types',
  AMENITIES = 'amenities',
  BEDROOMS = 'bedrooms',
  BATHROOMS = 'bathrooms',
  COMPLETION_TYPE = 'finishing',
  MIN_PRICE = 'min_price',
  MAX_PRICE = 'max_price',
  MIN_UNIT_AREA = 'min_unit_area',
  MAX_UNIT_AREA = 'max_unit_area',
  DELIVERY_DATE = 'delivery_date',
  DOWN_PAYMENT = 'down_payment',
  MONTHLY_INSTALLMENTS = 'monthly_installments',
  INSTALLMENT_YEARS = 'installment_years',
  SALE_TYPE = 'sale_type',
  SORT_BY = 'sort_by',
  HAS_GARDEN = 'has_garden',
  HAS_ROOF = 'has_roof',
  NAWY_NOW = 'nawy_now'
}

export interface SearchFiltersInterface {
  searchFilters: {
    category: string;
    page_number: number | undefined;
    compounds: number[];
    areas: number[];
    developers: number[];
    property_types: number[];
    amenities: number[];
    bedrooms: number[];
    bathrooms: number[];
    finishing: string[];
    min_price?: number;
    max_price?: number;
    min_unit_area?: number;
    max_unit_area?: number;
    delivery_date?: string;
    down_payment?: number;
    monthly_installments?: number;
    installment_years: number[];
    sale_type: string[];
    sort_by: {
      order_by?: string;
      order_direction?: string;
    };
    has_garden: boolean;
    has_roof: boolean;
    nawy_now?: boolean;
  };
  searchBody: any;
}

export const searchFiltersInitialState = {
  searchFilters: {
    category: 'compound',
    page_number: undefined,
    compounds: [],
    areas: [],
    developers: [],
    property_types: [],
    amenities: [],
    bedrooms: [],
    bathrooms: [],
    finishing: [],
    min_price: undefined,
    max_price: undefined,
    min_unit_area: undefined,
    max_unit_area: undefined,
    delivery_date: undefined,
    down_payment: undefined,
    monthly_installments: undefined,
    installment_years: [],
    sale_type: [],
    sort_by: {},
    has_garden: false,
    has_roof: false,
    nawy_now: false
  },
  searchBody: {
    show: 'compound',
    start: 1
  }
};

export const searchFiltersReducer = (
  state: SearchFiltersInterface,
  action: SearchFiltersActions
) => {
  switch (action.type) {
    case SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE:
      return {
        searchFilters: {
          ...state.searchFilters,
          [action.payload.name]: action.payload.value
        },
        searchBody: {
          ...state.searchBody,
          [mapSearchFiltersKeysToSearchApiKeys(action.payload.name)]:
            mapSearchFiltersValuesToSearchApiValues(
              action.payload.name,
              action.payload.value
            )
        }
      };

    case SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT:
      const multiSelectFilterBodyValue =
        action.payload.value?.length > 0 ? action.payload.value : undefined;
      return {
        searchFilters: {
          ...state.searchFilters,
          [action.payload.name]: action.payload.value
        },
        searchBody: {
          ...state.searchBody,
          [mapSearchFiltersKeysToSearchApiKeys(action.payload.name)]:
            mapSearchFiltersValuesToSearchApiValues(
              action.payload.name,
              multiSelectFilterBodyValue
            )
        }
      };

    case SearchFiltersActionTypes.UPDATE_PAGE_NUMBER:
      return {
        searchFilters: {
          ...state.searchFilters,
          [action.payload.name]: action.payload.value
        },
        searchBody: {
          ...state.searchBody,
          [mapSearchFiltersKeysToSearchApiKeys(action.payload.name)]:
            mapSearchFiltersValuesToSearchApiValues(
              action.payload.name,
              action.payload.value
            )
        }
      };
    case SearchFiltersActionTypes.UPDATE_SORTING_ORDER:
      const sortingValue = {
        order_by: action.payload.order_by,
        order_direction: action.payload.order_direction
      };
      return {
        searchFilters: {
          ...state.searchFilters,
          sort_by: sortingValue
        },
        searchBody: {
          ...state.searchBody,
          ...sortingValue
        }
      };
    case SearchFiltersActionTypes.RESET:
      if (Router.pathname === '/' || Router.pathname.includes('/buy'))
        return searchFiltersInitialState;
      return {
        searchFilters: {
          ...searchFiltersInitialState.searchFilters,
          [SearchFiltersTypes.COMPOUNDS]: Router.pathname.includes('/nawy-now')
            ? []
            : state.searchFilters.compounds,
          [SearchFiltersTypes.AREAS]: Router.pathname.includes('/nawy-now')
            ? []
            : state.searchFilters.areas,
          [SearchFiltersTypes.DEVELOPERS]: state.searchFilters.developers,
          [SearchFiltersTypes.NAWY_NOW]: Router.pathname.includes('/nawy-now')
            ? true
            : undefined,
          [SearchFiltersTypes.DELIVERY_DATE]: Router.pathname.includes(
            '/nawy-now'
          )
            ? state.searchFilters.delivery_date
            : undefined,
          [SearchFiltersTypes.CATEGORY]: Router.pathname.includes('/nawy-now')
            ? 'property'
            : 'compound'
        },
        searchBody: {
          ...searchFiltersInitialState.searchBody,
          compounds_ids: Router.pathname.includes('/nawy-now')
            ? undefined
            : state.searchBody.compounds_ids,
          areas_ids: Router.pathname.includes('/nawy-now')
            ? undefined
            : state.searchBody.areas_ids,
          developers_ids: state.searchBody.developers_ids,
          nawy_now: Router.pathname.includes('/nawy-now') ? true : undefined,
          delivery_date: Router.pathname.includes('/nawy-now')
            ? state.searchBody.delivery_date
            : undefined,
          show: Router.pathname.includes('/nawy-now') ? 'property' : 'compound'
        }
      };

    case SearchFiltersActionTypes.SET_INITIAL_FILTERS:
      const { initial_filters } = action.payload;

      const initialFilters = initial_filters.reduce(
        (state, filter) => ({
          ...state,
          [filter.filter]: filter.value
        }),
        {}
      );
      const mappedSearchFilters = initial_filters.reduce(
        (state, filter) => ({
          ...state,
          [mapSearchFiltersKeysToSearchApiKeys(filter.filter)]:
            mapSearchFiltersValuesToSearchApiValues(filter.filter, filter.value)
        }),
        {}
      );
      return {
        searchFilters: {
          ...searchFiltersInitialState.searchFilters,
          ...initialFilters
        },
        searchBody: {
          ...searchFiltersInitialState.searchBody,
          ...mappedSearchFilters
        }
      };
    default:
      return state;
  }
};
