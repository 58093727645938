import { useRouter } from 'next/router';
import styled from 'styled-components';

const Dropdown = ({ children, ...props }: any) => {
  const { locale } = useRouter();
  return (
    <Container
      className={props.className}
      locale={locale}
      isInvalid={props.isInvalid}
      isPhone={props.isPhone}
      isDisabled={props.isDisabled}
    >
      {children}
    </Container>
  );
};

const Container = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 100%;
  flex: ${props => props.isPhone && '0 0 10%'};

  .select {
    width: 100%;

    &.select-country {
      .select__value-container {
        display: block;
        visibility: hidden;
        width: 0px;
      }
    }

    .select__control {
      padding-inline-start: ${props => props.isPhone && '16px'};
      background: ${props =>
        props.isInvalid ? 'rgba(238, 0, 0, 0.05)' : '#FFFFFF'} !important;
      border: ${props =>
        props.isInvalid ? '1px solid #EE0000' : '1px solid #e6e6e6'};
      border-radius: ${props => props.theme.borderRadius};
      height: 45px;
      border: ${props => props.isPhone && 'none'};
    }

    .select__control--is-focused {
      display: flex;
      align-items: center;
      box-shadow: none;
      height: 45px;
      border-radius: ${props => props.theme.borderRadius};
      background: ${props =>
        props.isInvalid ? 'rgba(238, 0, 0, 0.05)' : '#FFFFFF'} !important;
      border: ${props =>
        props.isInvalid ? '1px solid #EE0000' : '1px solid #e6e6e6'};
      border: ${props => props.isPhone && 'none'};
    }

    .select__indicators {
      height: ${props => props.isPhone && '45px'};
      max-height: 45px;
    }

    .select__indicator-separator {
      display: none;
    }
    .select__indicator {
      cursor: pointer;
    }

    .select__value-container {
      display: ${props => props.isPhone && 'none'};
      width: 50%;
      height: 45px !important;

      .select__input-container {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
      }
      padding: ${props => (props.isPhone ? '0' : '0 16px')};
      position: unset;
      .select__placeholder {
        font-size: 16px;
        color: ${props => props.theme.light.colors.darkGrey};
      }
      .select__single-value {
        font-size: 14px;
        color: #000;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .select__menu {
      max-width: ${props =>
        props.className === 'nawy-now-dropdown' ? '100%' : '370px'};
      min-width: ${props =>
        props.className === 'nawy-now-dropdown' ? '' : '270px'};
      height: 120px;
      border-radius: ${props => props.theme.borderRadius};
      z-index: 10;
      border: ${props =>
        props.isInvalid ? '1px solid #EE0000' : '1px solid #e6e6e6'};
      text-align: ${props => (props.locale === 'en' ? 'left' : 'right')};
      font-size: 12px;
      color: #000;
      .select__menu-list {
        height: 118px;
        border-radius: ${props => props.theme.borderRadius};
      }
    }
  }
  .form-errors {
    font-size: ${props => props.theme.font.sizes.xxs};
    color: #e70800;
    margin-top: 4px;
    margin-inline-start: 5px;
    font-family: materialRegular;
  }

  &.compound-dropdown {
    pointer-events: ${props => (props.isDisabled ? 'none' : 'all')};
    .select__control {
      background: ${props =>
        props.isDisabled ? 'rgb(241, 241, 241)' : '#FFFFFF'} !important;
    }
  }
`;

export default Dropdown;
