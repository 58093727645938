import Link from 'next/link';
import { useRouter } from 'next/router';
import { logEvent } from '../../../new-lib/analytics';
import Header from './HeaderPresentation';
import MobileHeader from './MobileHeaderPresentation';
import Language from '../../../public/assets/icons/common/language.svg';
import ImageWrapper from '../../ImageWrapper';
import IconHolder from '../../ui/IconHolder';

interface HeaderContainerProps {
  paths: {
    en: string;
    ar: string;
  };
  forceMobile?: boolean;
  loadedSearchResultsSSR?: any;
}

export interface HeaderPresentationProps extends HeaderContainerProps {
  handleNavbarItemClick: (eventName: string, eventFeature: string) => void;
  isMobile?: boolean;
}

interface HeaderLanguageProps extends HeaderContainerProps {
  showIcon: boolean;
}

export const HeaderLanguage = ({ paths, showIcon }: HeaderLanguageProps) => {
  const { locale, asPath } = useRouter();
  const getQueryUrl = () => {
    if (asPath.split('?')[1]) {
      return '?' + asPath.split('?')[1];
    }
    return '';
  };

  const constructGoToRef = () => {
    if (locale === 'en') {
      return paths.ar + getQueryUrl();
    } else {
      return paths.en + getQueryUrl();
    }
  };
  const lang = locale === 'ar' ? 'English' : 'العربية';
  const goToRef = constructGoToRef();
  const linkLocale = locale == 'en' ? 'ar' : 'en';

  return (
    <Link
      href={goToRef}
      locale={linkLocale}
      style={{ display: 'flex', alignItems: 'center' }}
      legacyBehavior
    >
      <div
        className="language-icon"
        onClick={() => logEvent.click('locale_change', 'navbar', 'navbar')}
        data-test="switch-lang-btn"
      >
        {showIcon && (
          <IconHolder>
            <ImageWrapper
              src={Language}
              alt="Language"
              width={30}
              height={40}
            />
          </IconHolder>
        )}
        <span>{lang}</span>
      </div>
    </Link>
  );
};

const HeaderContainer = ({
  paths,
  forceMobile,
  loadedSearchResultsSSR
}: HeaderContainerProps) => {

  const handleNavbarItemClick = (eventName: string, eventFeature: string) => {
    logEvent.click(eventName, 'header', eventFeature);
  };

  const MobileViewHeader: JSX.Element = (
    <MobileHeader
      paths={paths}
      handleNavbarItemClick={handleNavbarItemClick}
      loadedSearchResultsSSR={loadedSearchResultsSSR}
    />
  );
  const DesktopViewHeader: JSX.Element = (
    <Header
      paths={paths}
      handleNavbarItemClick={handleNavbarItemClick}
      loadedSearchResultsSSR={loadedSearchResultsSSR}
    />
  );

  return forceMobile ? MobileViewHeader : DesktopViewHeader;
};
export default HeaderContainer;