import {
  SearchFiltersInterface,
  SearchFiltersTypes
} from '../../../reducers/search-filters/reducer';

type AnyFunction = (...args: any[]) => void;

export default function debounce(fn: AnyFunction, delay: number = 250) {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  const debounced = function (...args: any[]) {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      fn(...args);
    }, delay);
  };

  debounced.cancel = () => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }
  };

  return debounced;
}

export const generateQueryParams = (
  searchFilters: SearchFiltersInterface['searchFilters']
): string => {
  const params = new URLSearchParams();

  Object.entries(searchFilters).forEach(([key, value]) => {
    if (
      key === SearchFiltersTypes.CATEGORY ||
      key === SearchFiltersTypes.PAGE_NUMBER ||
      key === SearchFiltersTypes.SORT_BY ||
      value === undefined ||
      value === null ||
      (Array.isArray(value) && value.length === 0) ||
      (typeof value === 'object' && Object.keys(value).length === 0)
    ) {
      return;
    }

    switch (key) {
      case SearchFiltersTypes.MIN_PRICE:
      case SearchFiltersTypes.MAX_PRICE:
      case SearchFiltersTypes.MIN_UNIT_AREA:
      case SearchFiltersTypes.MAX_UNIT_AREA:
      case SearchFiltersTypes.DOWN_PAYMENT:
      case SearchFiltersTypes.MONTHLY_INSTALLMENTS:
        if (!isNaN(value as number)) {
          params.append(key, value.toString());
        }
        break;

      case SearchFiltersTypes.DELIVERY_DATE:
        params.append(key, value as string);
        break;

      case SearchFiltersTypes.COMPOUNDS:
      case SearchFiltersTypes.AREAS:
      case SearchFiltersTypes.DEVELOPERS:
      case SearchFiltersTypes.PROPERTY_TYPES:
      case SearchFiltersTypes.AMENITIES:
      case SearchFiltersTypes.BEDROOMS:
      case SearchFiltersTypes.BATHROOMS:
      case SearchFiltersTypes.INSTALLMENT_YEARS:
        if (Array.isArray(value) && value.length > 0) {
          params.append(key, (value as number[]).join(','));
        }
        break;

      case SearchFiltersTypes.COMPLETION_TYPE:
      case SearchFiltersTypes.SALE_TYPE:
        if (Array.isArray(value) && value.length > 0) {
          params.append(key, (value as string[]).join(','));
        }
        break;

      case SearchFiltersTypes.HAS_GARDEN:
      case SearchFiltersTypes.HAS_ROOF:
      case SearchFiltersTypes.NAWY_NOW:
        value === true && params.append(key, value.toString());
        break;

      default:
        break;
    }
  });

  return params.toString();
};
