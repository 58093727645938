import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
} from 'react';
import styled from 'styled-components';
import { getPageLocation } from '../../../../../helpers/common';
import SearchIcon from '../../../../../new-components/svg-icons/SearchIcon';
import { logEvent } from '../../../../../new-lib/analytics';
import debounce from '../../../utils';
import { SearchSelection } from '../../../hooks/LocationFilter';
import { LocationSelectedItem } from './LocationSearchSelections';

interface filterProps {
  dropdownOpen: boolean;
  setDropdownOpen: Dispatch<SetStateAction<boolean>>;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  selectedSearches: SearchSelection[];
  handleRemove: (selection: SearchSelection) => void;
  searchBarRef?: any;
}

const LocationSearchBar = ({
  dropdownOpen,
  setDropdownOpen,
  setSearchTerm,
  selectedSearches,
  handleRemove,
  searchBarRef,
}: filterProps): JSX.Element => {
  const { t } = useTranslation(['common', 'search']);
  const { pathname } = useRouter();

  useEffect(() => {
    if (dropdownOpen && searchBarRef?.current) {
      searchBarRef?.current?.focus();
    }
  }, [dropdownOpen]);

  const handleEnter = (e: any) => {
    if (e.key == 'Enter') {
      setDropdownOpen(false);
    }
  };

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    debouncedResults(e.currentTarget.value);
  };

  const debouncedResults = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
    }, 500),
    []
  );

  return dropdownOpen ? (
    <OpenedInputContainer className="opened-input">
      <input
        type="text"
        name="search-input"
        id="search-input"
        ref={searchBarRef}
        autoFocus
        onChange={e => handleInputChange(e)}
        onKeyDown={e => handleEnter(e)}
        autoComplete="off"
        data-cy="search-input"
      />
    </OpenedInputContainer>
  ) : (
    <ClosedInputContainer
      onClick={() => {
        setTimeout(() => setDropdownOpen(true), 0);
        logEvent.click('search_click', getPageLocation(pathname), 'search');
      }}
      className="closed-input"
      data-test="closed-search-input"
    >
      {selectedSearches.length > 0 ? (
        <>
          <LocationSelectedItem
            selection={selectedSearches[0]}
            handleRemove={handleRemove}
          />
          {selectedSearches.length > 1 && (
            <div className="selected-search more-searches">
              <span>
                {selectedSearches.length - 1} {t('more')}
              </span>
            </div>
          )}
        </>
      ) : (
        <>
          <SearchIcon />
          <span className="placeholder">{t('search:search')}</span>
        </>
      )}
    </ClosedInputContainer>
  );
};

const ClosedInputContainer = styled.div`
  width: 100%;
  border: 1px solid ${props => props.theme.light.borderColors.primary};
  border-radius: 8px;
  padding: 12px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  height: 100%;
  overflow: hidden;
  background-color: #ffffff;
  height: 55px;
  svg {
    width: 17.5px;
    height: 17.5px;
    path {
      fill: ${props => props.theme.light.colors.grey};
    }
  }

  span {
    margin-inline-start: 8px;
    font-size: 14px;
    color: ${props => props.theme.light.colors.grey};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .selected-search {
    margin-inline-end: 4px;
    max-width: 150px;
    background-color: #e4ebf2;
    
    span {
      color: ${props => props.theme.light.colors.black};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      font-family: materialMedium;
      margin-inline-start: 0;
    }

    &.more-searches {
      border-radius: 8px;
      padding: 4px 8px;
    }
  }

`;
const OpenedInputContainer = styled.div`
  width: 100%;
  border: 1px solid ${props => props.theme.light.borderColors.primary};
  border-radius: 8px;
  background-color: #ffffff;
  height: 55px;

  input {
    width: 100%;
    padding: 12px 8px;
    outline: none;
    border: none;
    background: none;
    color: ${props => props.theme.light.colors.black};
    font-size: 14px !important;
    height: 100%;
  }

`;

export default LocationSearchBar;
