import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import styled, { css, keyframes } from 'styled-components';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { HeaderLanguage, HeaderPresentationProps } from './HeaderContainer';
import { CSSTransition } from 'react-transition-group';

// Assets
import FavIcon from '../../svg-icons/FavoriteIcon';
import HeaderMenu from './HeaderMenu';
import useOnClickOutside from '../../../hooks/ClickOutside';
import SearchBar from '../../../features/search/components/search-bar/SearchBar';
import FilterMenu from '../../../features/search/components/filters/FilterMenuPresentation';
import MenuBurgerIcon from '../../svg-icons/MenuBurgerIcon';

const Header = ({
  paths,
  handleNavbarItemClick,
}: HeaderPresentationProps) => {
  const { locale, asPath, pathname } = useRouter();
  const { t } = useTranslation('common');
  const fields = asPath.split('?');
  const [openSideMenu, setOpenSideMenu] = useState(false);
  const menuRef = useRef(null);
  useOnClickOutside(menuRef, menuRef, () => setOpenSideMenu(false));

  const pathsWithNav = [
    '/',
    '/cityscape',
    '/compare',
    '/sahel-map',
    '/nawy-now'
  ];

  useEffect(() => {
    if (!document) return;
    let body = document.body;
    let tag: HTMLDivElement | null = null;

    if (openSideMenu) {
      tag = document.createElement('div');
      tag.classList.add('overlay-screen');
      body.appendChild(tag);
      body.style.pointerEvents = 'none';
    } else {
      if (tag) body.removeChild(tag);
      body.style.pointerEvents = 'all';
    }

    return () => {
      if (tag && body.contains(tag)) {
        body.removeChild(tag);
      }
    };
  }, [openSideMenu]);

  return (
    <>
      <HeaderContainer
        id="header"
        data-test="desktop-navbar"
        itemScope
        itemType="https://schema.org/WPHeader"
      >
        <HeaderWrapper className="container-fluid">
          <div className="icons-wrapper">
            {!pathsWithNav.includes(pathname) && (
              <div
                onClick={() => setOpenSideMenu(true)}
                style={{ cursor: 'pointer' }}
              >
                <MenuBurgerIcon width={28} height={16} />
              </div>
            )}
            <Link
              itemProp="url"
              href="/"
              locale={locale}
              prefetch={false}
              legacyBehavior
            >
              <a
                data-test="home-btn"
                className="logo"
                onClick={() =>
                  handleNavbarItemClick('nawy_logo_click', 'other')
                }
              >
                <Image
                  src="/assets/icons/common/nawy.svg"
                  alt="Nawy"
                  width={102}
                  height={25}
                  itemProp="image"
                />
              </a>
            </Link>
          </div>
          {pathsWithNav.includes(pathname) ? (
            <HeaderLinks locale={locale}>
              <Link itemProp="url" href="/" prefetch={false} legacyBehavior>
                <a
                  data-test="home-btn"
                  onClick={() => {
                    handleNavbarItemClick('home_click', 'other');
                  }}
                >
                  <span className={fields[0] === '/' ? 'focus' : ''}>
                    {t('Home')}
                  </span>
                </a>
              </Link>
              <Link
                itemProp="url"
                href="/search"
                prefetch={false}
                legacyBehavior
              >
                <a
                  data-test="search-btn"
                  onClick={() => {
                    handleNavbarItemClick('search_click', 'other');
                  }}
                >
                  <span className={fields[0] === '/search' ? 'focus' : ''}>
                    {t('Search')}
                  </span>
                </a>
              </Link>
              <Link
                itemProp="url"
                href="/sell-my-property"
                prefetch={false}
                legacyBehavior
              >
                <a
                  onClick={() => {
                    handleNavbarItemClick('sell_click', 'other');
                  }}
                >
                  <span
                    className={fields[0] === '/sell-my-property' ? 'focus' : ''}
                  >
                    {t('sell')}
                  </span>
                </a>
              </Link>
              <a
                data-test="blog-btn"
                href={`https://www.nawy.com/blog${locale == 'ar' ? '/ar' : ''}`}
                onClick={() => {
                  handleNavbarItemClick('blog_click', 'other');
                }}
                target="_blank"
                rel="noreferrer"
              >
                <span> {t('Blog')} </span>
              </a>
              <Link
                itemProp="url"
                href="/about-us"
                prefetch={false}
                legacyBehavior
              >
                <a
                  data-test="about-btn"
                  onClick={() => {
                    handleNavbarItemClick('about_us_click', 'other');
                  }}
                >
                  <span className={fields[0] === '/about-us' ? 'focus' : ''}>
                    {t('About')}
                  </span>
                </a>
              </Link>
              <Link
                itemProp="url"
                href="/contact"
                prefetch={false}
                legacyBehavior
              >
                <a
                  data-test="contact-btn"
                  onClick={() => {
                    handleNavbarItemClick('contact_us_click', 'other');
                  }}
                >
                  <span className={fields[0] === '/contact' ? 'focus' : ''}>
                    {t('Contact')}
                  </span>
                </a>
              </Link>
              <Link
                itemProp="url"
                href="/nawy-now"
                prefetch={false}
                legacyBehavior
              >
                <a
                  onClick={() => {
                    handleNavbarItemClick('nawynow_click', 'other');
                  }}
                >
                  <span className={fields[0] === '/nawy-now' ? 'focus' : ''}>
                    Nawy Now
                  </span>
                </a>
              </Link>
              <a
                data-test="careers-btn"
                href="https://apply.workable.com/nawy-real-estate/"
                onClick={() => {
                  handleNavbarItemClick('careers_click', 'other');
                }}
                target="_blank"
                rel="noreferrer"
              >
                <span className="careers"> {t('careers')} </span>
              </a>
            </HeaderLinks>
          ) : (
            <SearchBarWrapper>
              <SearchBar />
            </SearchBarWrapper>
          )}
          <HeaderSettings>
            <ul>
              <li>
                <Link
                  itemProp="url"
                  href="/favorites"
                  prefetch={false}
                  legacyBehavior
                >
                  <a
                    data-test="fav-btn"
                    onClick={() => {
                      handleNavbarItemClick('favorites_click', 'other');
                    }}
                    className="fav-icon"
                    aria-label="fav-link"
                  >
                    <FavIcon stroke="#8E8E8E" fill="transparent" />
                  </a>
                </Link>
              </li>
              <HeaderLanguage paths={paths} showIcon={false} />
            </ul>
          </HeaderSettings>
        </HeaderWrapper>
      </HeaderContainer>
      <CSSTransition
        in={openSideMenu}
        timeout={300}
        unmountOnExit
        classNames="menu"
      >
        <ViewContent ref={menuRef} dir={locale}>
          <HeaderMenu
            paths={paths}
            handleNavbarItemClick={handleNavbarItemClick}
            setIsOpen={setOpenSideMenu}
          />
        </ViewContent>
      </CSSTransition>
      <FilterMenu />
    </>
  );
};

// Animation for LTR layout
const slideInFromLeft = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

// Corrected Animation for RTL layout
const slideInFromRight = keyframes`
  from {
    transform: translateX(100%); /* Start from the right side */
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const ViewContent = styled.div<{ dir: string | undefined }>`
  position: fixed;
  top: 0px;
  inset-inline-start: 0px;
  width: 500px;
  height: 100vh;
  padding: 32px;
  background-color: ${props => props.theme.light.colors.white} !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999999;
  pointer-events: all;

  ${({ dir }) =>
    dir === 'ar'
      ? css`
          right: 0;
          animation: ${slideInFromRight} 0.2s ease forwards;
        `
      : css`
          left: 0;
          animation: ${slideInFromLeft} 0.2s ease forwards;
        `}
  will-change: transform, opacity;
`;
const HeaderContainer = styled.div`
  width: 100%;
  background-color: ${props => props.theme.light.colors.white};
  position: sticky;
  top: 0;
  z-index: 9999;
  border-bottom: 1px solid #e6e6e6;
`;
const HeaderWrapper = styled.div`
  height: 70px;
  margin: auto;
  background-color: ${props => props.theme.light.colors.white};
  border-bottom: ${props => props.theme.light.borderColors.primary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    display: flex;
    vertical-align: middle;
    line-height: normal;
    img {
      min-width: 90px;
      min-height: 23px;
    }
  }
  .icons-wrapper {
    display: flex;
    gap: 5px;
    align-items: flex-start;
    img {
      cursor: pointer;
    }
  }
`;
const HeaderLinks = styled.div<any>`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 28px;
  a {
    align-self: center;
    display: flex;
    align-items: center;
    font-size: ${props => props.theme.font.sizes.s};
    font-family: materialMedium;
    color: ${props => props.theme.light.colors.darkGrey};
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    &:hover {
      color: ${props => props.theme.light.colors.primaryBlue} !important;
    }
  }
  .focus {
    color: ${props => props.theme.light.colors.black};
  }

  .careers {
    position: relative;
    white-space: nowrap;
    &::after {
      content: ${props => (props.locale === 'ar' ? '"جديد"' : '"New"')};
      position: absolute;
      top: -12px;
      right: ${props => (props.locale == 'en' ? '-28px' : 'unset')};
      left: ${props => (props.locale == 'ar' ? '-28px' : 'unset')};
      font-size: calc(${props => props.theme.font.sizes.xxs} - 4px);
      font-family: materialMedium;
      color: ${props => props.theme.light.colors.white};
      background-color: ${props => props.theme.light.colors.primaryOrange};
      padding: 2.5px 5px;
      border-radius: 4px;
    }
  }
`;

const SearchBarWrapper = styled.div`
  z-index: 99;
  width: 50%;
  max-width: 700px;
  height: 45px;
`;

const HeaderSettings = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 25px;
  ul {
    display: flex;
    gap: 16px;
    li,
    .language-icon {
      display: flex;
      align-items: center;
      font-size: ${props => props.theme.font.sizes.s};
      font-family: materialMedium;
      color: ${props => props.theme.light.colors.primaryBlue};
      text-transform: capitalize;
      cursor: pointer;
      cursor: pointer;
      &:hover {
        color: ${props => props.theme.light.colors.primaryOrange};
      }
    }

    .fav-icon {
      svg {
        width: 35px;
        height: auto;
      }
    }
  }
`;

export default Header;
