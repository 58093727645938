import { useTranslation } from 'next-i18next';
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { InputOption } from '../../../../../new-lib/types/search-filters';
import { SearchFiltersTypes } from '../../../../../reducers/search-filters/reducer';
import {
  SearchContext,
  SearchContextInterface
} from '../../../context/SearchContext';

interface filterProps {
  filterType: SearchFiltersTypes.CATEGORY;
  options: InputOption[];
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void;
}

const ToggleFilterPresentation = ({
  options,
  handleChange,
  filterType
}: filterProps): JSX.Element => {
  const { t } = useTranslation('search');
  const theme = useContext(ThemeContext);
  const { searchFiltersState } = React.useContext(
    SearchContext
  ) as SearchContextInterface;
  return (
    <FilterOptions>
      {options.map(option => (
        <div className="toggle-option" key={option.value}>
          <input
            type="radio"
            name={filterType}
            id={option.value.toString()}
            onChange={e => {
              handleChange(e);
            }}
            value={option.value}
            checked={
              searchFiltersState.searchFilters[filterType] == option.value
            }
            style={{
              border:
                searchFiltersState.searchFilters[filterType] == option.value
                  ? `5px solid ${theme.light.colors.secondary}`
                  : `5px solid ${theme.light.colors.lightGrey}`
            }}
            data-cy={`${option.value}-radio`}
          />
          <label htmlFor={option.value.toString()}>
            {t(`${option.options.displayedValue}`)}
          </label>
        </div>
      ))}
    </FilterOptions>
  );
};

const FilterOptions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;

  .toggle-option {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    input {
      appearance: none;
      -webkit-appearance: none;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background: ${props => props.theme.light.colors.lightGrey};
      border: 5px solid ${props => props.theme.light.colors.secondary};
      box-shadow: 0 0 0 4px #d9d9d9;
      accent-color: ${props => props.theme.light.colors.secondary};
      margin-left: 4px;
      cursor: pointer;
      transition: all 0.2s ease-out;
    }
    label {
      cursor: pointer;
      font-size: ${props => props.theme.font.sizes.s};
      font-family: materialSemiBold;
      color: ${props => props.theme.light.colors.black};
    }
  }
`;

export default ToggleFilterPresentation;
