import Script from 'next/script';

declare global {
  interface Window {
    Moengage: {
      track_event: (name: string, params: any) => void;
      add_unique_user_id: (id: string) => Promise<void>;
      destroy_session: () => void;
      add_mobile: (phone: string) => void;
      add_email: (email: string) => void;
      add_user_attribute: (key: string, value: string) => void;
      add_last_name: (name: string) => void;
      add_first_name: (name: string) => void;
      add_user_name: (name: string) => void;
    };
  }
}

export const MoengageScript = () => {
  const MoEngage = (
    <>
      {process.env.NEXT_PUBLIC_MOENGAGE_DATA_CENTER &&
        process.env.NEXT_PUBLIC_MOENGAGE_APP_ID && (
          <Script
            id="Moengage"
            strategy="afterInteractive"
            type="text/javascript"
          >
            {`
              var moeDataCenter = "${process.env.NEXT_PUBLIC_MOENGAGE_DATA_CENTER}"; // Replace "DC" with the actual Data center value from the above table
              var moeAppID = "${process.env.NEXT_PUBLIC_MOENGAGE_APP_ID}"; // Replace "AppID" available in the settings page of MoEngage Dashboard.
                    
              !function(e,n,i,t,a,r,o,d){if(!moeDataCenter||!moeDataCenter.match(/^dc_[0-9]+$/gm))return console.error("Data center has not been passed correctly. Please follow the SDK installation instruction carefully.");var s=e[a]=e[a]||[];if(s.invoked=0,s.initialised>0||s.invoked>0)return console.error("MoEngage Web SDK initialised multiple times. Please integrate the Web SDK only once!"),!1;e.moengage_object=a;var l={},g=function n(i){return function(){for(var n=arguments.length,t=Array(n),a=0;a<n;a++)t[a]=arguments[a];(e.moengage_q=e.moengage_q||[]).push({f:i,a:t})}},u=["track_event","add_user_attribute","add_first_name","add_last_name","add_email","add_mobile","add_user_name","add_gender","add_birthday","destroy_session","add_unique_user_id","update_unique_user_id","moe_events","call_web_push","track","location_type_attribute"],m={onsite:["getData","registerCallback"]};for(var c in u)l[u[c]]=g(u[c]);for(var v in m)for(var f in m[v])null==l[v]&&(l[v]={}),l[v][m[v][f]]=g(v+"."+m[v][f]);r=n.createElement(i),o=n.getElementsByTagName("head")[0],r.async=1,r.src=t,o.appendChild(r),e.moe=e.moe||function(){return(s.invoked=s.invoked+1,s.invoked>1)?(console.error("MoEngage Web SDK initialised multiple times. Please integrate the Web SDK only once!"),!1):(d=arguments.length<=0?void 0:arguments[0],l)},r.addEventListener("load",function(){if(d)return e[a]=e.moe(d),e[a].initialised=e[a].initialised+1||1,!0}),r.addEventListener("error",function(){return console.error("Moengage Web SDK loading failed."),!1})}(window,document,"script","https://cdn.moengage.com/release/"+moeDataCenter+"/moe_webSdk.min.latest.js","Moengage");
              
              Moengage = moe({
                app_id: moeAppID,
                debug_logs: ${process.env.NEXT_PUBLIC_MOENGAGE_DEBUG_MODE},
                enableSPA: true
              });`}
          </Script>
        )}
    </>
  );

  return (
    <>
      {process.env.NEXT_PUBLIC_MOENGAGE_APP_ID &&
        process.env.NEXT_PUBLIC_MOENGAGE_DATA_CENTER &&
        MoEngage}
    </>
  );
};
