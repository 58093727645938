import Cookies from 'universal-cookie';
import { objToQuery } from '../../helpers/common';
import { getTrackerClientId } from '../analytics';
import { PHASE_PRODUCTION_BUILD } from 'next/constants';

const cookies = new Cookies();
const clientId = cookies.get('clientID');
let HOST = process.env.NEXT_PUBLIC_HOST;

const PUBLIC_API_URL = process.env.NEXT_PUBLIC_API;
const INTERNAL_API_URL = process.env.INTERNAL_API;

const getApiDomain = () => {
  return HOST === 'http://127.0.0.1:8000' ||
    process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD ||
    !process.env.INTERNAL_API
    ? PUBLIC_API_URL
    : INTERNAL_API_URL;
};

export const getClickWhatsAppEndpoint = (language?: string): string => {
  const query = { ...(language && { language }) };
  return `${getApiDomain()}/click_whatsapp?${objToQuery(query)}`;
};

export const getSearchResultsEndpoint = (language?: string) => {
  const query = { ...(language && { language }), client_id: clientId };
  return `${getApiDomain()}/properties/search?${objToQuery(query)}`;
};

export const getCompoundsSearchResultsEndpoint = (language?: string) => {
  const query = { ...(language && { language }), client_id: clientId };
  return `${getApiDomain()}/properties/compound_page_search?${objToQuery(
    query
  )}`;
};

export const getFilterOptionsEndpoint = (language?: string) => {
  const query = { ...(language && { language }) };
  return `${getApiDomain()}/properties/get_filter_options?${objToQuery(query)}`;
};

export const getSearchLocationsEndpoint = (
  term: string,
  language?: string,
  northcoast?: boolean,
  exclude?: string[]
) => {
  let query = {
    ...(language && { language }),
    ...(northcoast && { northcoast })
  };

  let excludeQuery = '';

  if (exclude && exclude.length > 0) {
    exclude.forEach(type => {
      excludeQuery += `&exclude_types[]=${type}`;
    });
  }

  return `${getApiDomain()}/locations?${objToQuery(
    query
  )}&search_term=${term}${excludeQuery}`;
};

export const getAreaFuzzyEndpoint = (term: string, language?: string) => {
  let query = {
    ...(language && { language })
  };

  return `${getApiDomain()}/areas/autocomplete?${objToQuery(
    query
  )}&search_term=${term}`;
};

export const getSearchCompoundEndpoint = (id: number, language?: string) => {
  const query = { ...(language && { language }) };
  return `${getApiDomain()}/compounds/${id}?${objToQuery(query)}`;
};

export const getSearchDeveloperEndpoint = (id: number, language?: string) => {
  const query = { ...(language && { language }) };
  return `${getApiDomain()}/developers/${id}?${objToQuery(query)}`;
};

export const getSearchAreaEndpoint = (id: number, language?: string) => {
  const query = { ...(language && { language }) };
  return `${getApiDomain()}/areas/${id}?${objToQuery(query)}`;
};

export const getPhoneLeadInfoEndPoint = (language?: string): string => {
  const query = { ...(language && { language }) };
  return `${getApiDomain()}/phone_leads_info?${objToQuery(query)}`;
};

export const getCompoundEndpoint = (
  slug: string,
  language?: string,
  detail?: string
) => {
  const query = {
    slug,
    ...(language && { language }),
    ...(detail && { detail: detail })
  };
  return `${getApiDomain()}/compounds?${objToQuery(query)}`;
};

export const getTopAreasEndPoint = (language?: string) => {
  const query = { ...(language && { language }) };
  return `${getApiDomain()}/areas?with_summary=true&${objToQuery(query)}`;
};

export const getAvailablePropertyTypesEndpoint = (
  compoundId: number,
  language?: string
): string => {
  const query = { ...(language && { language }) };
  return `${getApiDomain()}/compounds/${compoundId}/get_available_property_types?${objToQuery(
    query
  )}`;
};

export const getPropertyEndpoint = (
  slug: string,
  language?: string,
  detail?: string
) => {
  const query = {
    slug,
    ...(language && { language }),
    ...(detail && { detail: detail })
  };
  return `${getApiDomain()}/properties?${objToQuery(query)}`;
};

export const getCompoundsCachedEndpoint = (
  language?: string,
  moveNowOnly?: boolean
) => {
  const query = { ...(language && { language, moveNowOnly }) };
  return `${HOST}/api/getCompoundsCached?${objToQuery(query)}`;
};
export const getAreasCachedEndpoint = (
  language?: string,
  moveNowOnly?: boolean
) => {
  const query = { ...(language && { language, moveNowOnly }) };
  return `${HOST}/api/getAreasCached?${objToQuery(query)}`;
};

export const getCompoundsEndpoint = (
  language?: string,
  moveNowOnly?: boolean,
  body?: any
) => {
  const query = { ...(language && { language, moveNowOnly, ...body }) };
  return `${getApiDomain()}/compounds?${objToQuery(query)}`;
};

export const getFooterLinksEndpoint = (language?: string) => {
  const query = { ...(language && { language }) };
  return `${getApiDomain()}/get_footer_links?${objToQuery(query)}`;
};

export const getFooterLinksCachedEndpoint = (language?: string) => {
  const query = { ...(language && { language }) };
  return `${HOST}/api/getFooterLinksCached?${objToQuery(query)}`;
};

export const submitContactFormEndpoint = (language?: string) => {
  const query = { ...(language && { language }) };
  return `${getApiDomain()}/submit_form?${objToQuery(query)}`;
};

export const getPopularCompoundsEndpoint = (language?: string) => {
  const query = {
    ...(language && { language, summary_type: 'website_summary' })
  };
  return `${getApiDomain()}/compounds/popular_compounds/?${objToQuery(query)}`;
};

export const getFavoritesEndPoint = (clientId: string, language?: string) => {
  const query = { client_id: clientId, ...(language && { language }) };
  return `${getApiDomain()}/watchlists?${objToQuery(query)}`;
};

export const getRecentSearchEndpoint = async (language?: string) => {
  const client_id = await getTrackerClientId();
  const query = {
    client_id,
    ...(language && { language }),
    max_results_size: 3
  };
  return `${getApiDomain()}/recent_searches/?${objToQuery(query)}`;
};

export const getWatchListEndPoint = (action?: string) => {
  return `${getApiDomain()}/watchlists${
    action && action == 'unfollow' ? '/unfollow' : ''
  }?`;
};

export const getAreaEndpoint = (slug: string, language?: string) => {
  const query = { name: slug, ...(language && { language }) };
  return `${getApiDomain()}/areas?${objToQuery(query)}`;
};

export const getDeveloperEndpoint = (slug: string, language?: string) => {
  const query = { slug, ...(language && { language }) };
  return `${getApiDomain()}/developers?${objToQuery(query)}`;
};

export const getDevelopersEndpoint = (
  with_summary?: boolean,
  language?: string
) => {
  const query = {
    ...(with_summary && { with_summary: with_summary }),
    ...(language && { language })
  };
  return `${getApiDomain()}/developers?${objToQuery(query)}`;
};

export const getAreasEndpoint = (with_summary?: boolean, language?: string) => {
  const query = {
    ...(with_summary && { with_summary: with_summary }),
    ...(language && { language })
  };
  return `${getApiDomain()}/areas?${objToQuery(query)}`;
};

export const getRecommendedPropertiesEndpoint = async (
  language?: string,
  propertyID?: number | number[]
) => {
  const client_id = await getTrackerClientId();
  const query = {
    client_id,
    ...(propertyID && { property_id: propertyID }),
    ...(language && { language })
  };
  return `${getApiDomain()}/properties/get_featuring?${objToQuery(query)}`;
};

export const getTrackActivityEndpoint = (language?: string) => {
  const query = { ...(language && { language }) };
  return `${getApiDomain()}/track_activity?${objToQuery(query)}`;
};

export const getStatisticsEndpoint = (language?: string) => {
  const query = { ...(language && { language }) };
  return `${getApiDomain()}/properties/get_statistics?${objToQuery(query)}`;
};

export const getNewLandingPageEndpoint = (id: string) => {
  return `${getApiDomain()}/landing_pages/${id}/new_landing`;
};
export const submitPropertyMonitorFormEndpoint = (language?: string) => {
  const query = { ...(language && { language }) };
  return `${getApiDomain()}/property_monitor?${objToQuery(query)}`;
};
export const getForecastingEndpoint = (id: number) => {
  return `${getApiDomain()}/properties/${id}/get_forecasting`;
};
export const getRecommendedCompoundsEndpoint = (
  language?: string,
  id?: number
) => {
  const query = { ...(language && { language }) };
  return `${getApiDomain()}/compounds/${id}/get_recommendations?${objToQuery(
    query
  )}`;
};

export const getLaunchesEndpoint = (language?: string, summary?: string) => {
  const query = {
    ...(language && { language }),
    ...(summary && { summary })
  };
  return `${getApiDomain()}/launches/search?${objToQuery(query)}`;
};

export const getLaunchesAreasEndpoint = (language?: string) => {
  const query = { ...(language && { language }) };
  return `${getApiDomain()}/launches/areas?${objToQuery(query)}`;
};

export const getRecentlyLaunchedCompoundsEndpoint = (language?: string) => {
  const query = { ...(language && { language }) };
  return `${getApiDomain()}/compounds/recently_launch?${objToQuery(query)}`;
};

export const getConfigVariablesEndpoint = () => {
  return `${getApiDomain()}/configration_variables`;
};

export const getPropertyTypesEndpoint = (language?: string) => {
  const query = { ...(language && { language }) };
  return `${getApiDomain()}/property_types?${objToQuery(query)}`;
};

export const getLaunchEndpoint = (slug: string, language?: string) => {
  const query = { slug, ...(language && { language }) };
  return `${getApiDomain()}/launches?${objToQuery(query)}`;
};

export const getLaunchAreasEndpoint = (language?: string) => {
  const query = { ...(language && { language }) };
  return `${getApiDomain()}/launches/areas?${objToQuery(query)}`;
};

export const checkLaunchEndpoint = (slug: string) => {
  return `${getApiDomain()}/compounds/${encodeURI(slug)}/check_launch`;
};

export const getOffersEndpoint = (language?: string) => {
  const query = { ...(language && { language }) };
  return `${getApiDomain()}/offers?${objToQuery(query)}`;
};

export const getCityscapeSearchEndpoint = (term: string, language?: string) => {
  const query = { ...(language && { language }) };
  return `${getApiDomain()}/offers/autocomplete?${objToQuery(
    query
  )}&search_term=${term}`;
};

export const optimizeImageEndpoint = (image: string) => {
  const query = { image };
  return `${PUBLIC_API_URL}/images/generate_qualities?${objToQuery(query)}`;
};

export const getSahelCompoundsEndpoint = (
  language?: string,
  area_id?: number
) => {
  const query = { ...(language && { language }), ...(area_id && { area_id }) };

  return `${getApiDomain()}/compounds/sahel_search?${objToQuery(query)}`;
};

export const getSahelInfoEndpoint = (language?: string) => {
  const query = { ...(language && { language }) };

  return `${getApiDomain()}/areas/northcoast_map_info?${objToQuery(query)}`;
};
