import { useContext, useEffect, useRef, useState } from 'react';
import {
  SearchContext,
  SearchContextInterface
} from '../../context/SearchContext';
import { useSearch } from '../../hooks/SearchInput';
import styled, { ThemeContext } from 'styled-components';
import SearchInput from './SearchInput';
import SearchFilterBtn from './SearchFilterBtn';
import { useRouter } from 'next/router';
import SearchDropdown from './SearchDropdown';
import { useSelector } from 'react-redux';
import useOnClickOutside from '../../../../hooks/ClickOutside';
import { containsAnyWord } from '../../../../helpers/common';
import ButtonIcon from '../../../../new-components/ui/ButtonIcon';
import PrevArrowIcon from '../../../../new-components/svg-icons/PrevArrowIcon';

const isRouteWithFilters = (pathname: string) => {
  const routesWithSearch = [
    '/search',
    '/area',
    '/developer',
    '/compound',
    '/favorites',
    '/buy',
  ];
  return (
    containsAnyWord(pathname, routesWithSearch) &&
    !pathname.includes('/property')
  );
};

const SearchBar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const { showFilters, setShowFilters, setShowUniversalSearch, setSearching } =
    useContext(SearchContext) as SearchContextInterface;
  const isMobile = useSelector((state: any) => state.app.isMobile);
  const { pathname } = useRouter();
  const theme = useContext(ThemeContext);
  const { locale } = useRouter();

  const {
    setSearchTerm,
    searchResults,
    isLoading,
    isError,
    selectedSearches,
    clearSearch,
    handleSelect,
    handleRemove,
    appliedFilters
  } = useSearch({
    setDropdownOpen,
    searchInputLocation: 'UniversalSearch'
  });
  const dropdownRef = useRef(null);

  const closeMenu = () => {
    setShowUniversalSearch(false);
    setDropdownOpen(false);
    setSearching(false);
  };

  useOnClickOutside(dropdownRef, dropdownRef, () => {
    closeMenu();
  });

  useEffect(() => {
    if (document && isMobile && (showFilters || dropdownOpen)) {
      document.body.style.position = 'fixed';
    } else {
      document.body.style.position = 'unset';
    }
  }, [showFilters, dropdownOpen]);

  useEffect(() => {
    if (document && isMobile) {
      document
        .querySelector('.app-wrapper')
        ?.classList.add('search-bar-included');
    }
    return () => {
      document
        .querySelector('.app-wrapper')
        ?.classList.remove('search-bar-included');
    };
  }, [isMobile]);

  return (
    <Container ref={dropdownRef} className="container-fluid">
      <div className="search-filter-bar">
        {dropdownOpen && isMobile && (
          <ButtonIcon
            icon={
              <PrevArrowIcon
                stroke={theme.light.colors.black}
                styles={locale == 'ar' ? { transform: 'rotate(180deg)' } : {}}
              />
            }
            border="none"
            backgroundColor="transparent"
            color="none"
            onClick={closeMenu}
            containerClass="back-button"
          />
        )}
        <SearchInput
          loader={loader}
          setLoader={setLoader}
          dropdownOpen={dropdownOpen}
          setDropdownOpen={setDropdownOpen}
          setSearchTerm={setSearchTerm}
          selectedSearches={selectedSearches}
          handleRemove={handleRemove}
        />
        {isRouteWithFilters(pathname) && (
          <SearchFilterBtn
            setShowFilters={setShowFilters}
            appliedFilters={appliedFilters}
          />
        )}
      </div>

      {dropdownOpen && (
        <SearchDropdown
          setLoader={setLoader}
          clearSearch={clearSearch}
          handleRemove={handleRemove}
          handleSelect={handleSelect}
          searchResults={searchResults}
          isLoading={isLoading}
          isError={isError}
          selectedSearches={selectedSearches}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background-color: ${props => props.theme.light.colors.white};
  border-radius: 10px;
  position: relative;
  height: 100%;
  .search-filter-bar {
    display: flex;
    flex-direction: row;
    gap: 8px;
    height: 100%;
    align-items: center;
  }

  @media screen and (max-width: ${props =>
      props.theme.breakPoints.mobile.max}) {
    border-radius: 0;
    .search-filter-bar {
      padding: 16px 0;

      .back-button {
        width: 25px;
        button {
          justify-content: flex-start;
          svg {
            width: 12px;
          }
        }
      }
    }
  }
`;

export default SearchBar;
