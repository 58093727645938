import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  line-height: 1.25;
  font-weight: normal;
}

#nprogress .bar {
    z-index: 999999 !important;
}

:root {
  --toastify-color-info: #015C9A;
  --toastify-font-family: materialRegular;
  --toastify-text-color-light: #1E4164;
}

body {
  font-family: materialRegular;
  font-size: 16px;
  color: ${props => props.theme.light.colors.primaryBlue};
  background-color: ${props => props.theme.light.backgroundColor};
  min-height: 100vh;
  overflow-x: hidden !important;
  width: 100%;
  
  .app-wrapper{
    min-height: calc(100vh - 150px);

    @media screen and (max-width: ${props =>
      props.theme.breakPoints.mobile.max}) {
      margin-top: 60px;
      &.search-bar-included{
        margin-top: 135px;

      }
  }
  }

  .overlay-screen {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.light.colors.black};
    opacity: 50%;
    z-index: 9999;
  }
}

.image-gallery{
  margin-top: 0 !important;
  z-index: 99999999 !important;
}


.container-fluid {
  width: calc(100% - 60px);
  max-width: 1400px;
  margin: 0 auto;

}
  @media screen and (min-width: 1600px){
      max-width: 1540px;
  }
  
  @media screen and (min-width: ${props =>
    props.theme.breakPoints.mobile.min}) and (max-width: ${props =>
  props.theme.breakPoints.tablet.max}){
      .container-fluid {
        width: 100%;
        padding: 0px 16px;
      }

      .max-width{
        width: 100vw;
        margin-inline-start: calc(-50vw + 50%);
      }
  }

@media screen and (min-width: ${props => props.theme.breakPoints.tablet.min}){
  .container-fluid {
  width: 95%;
  margin: 0 auto;
  }
}

// 1300px
@media screen and (min-width: ${props => props.theme.breakPoints.desktop.min}){
  .container-fluid {
  width: 90%;
  max-width: 1240px;
  margin: 0 auto;
  }
}
@media screen and (min-width: ${props => props.theme.breakPoints.desktop.mid}){
  .container-fluid {
  max-width: 1360px;
  }
}


// Text
.headline-1 {
  font-family: materialBold;
  font-size: ${props => props.theme.font.sizes.xxl};
  color: ${props => props.theme.light.colors.primaryBlue};  
}

.headline-2 {
   font-family: materialBold;
   font-size: ${props => props.theme.font.sizes.xl};
   color: ${props => props.theme.light.colors.primaryBlue};
   @media screen and (max-width: ${props =>
     props.theme.breakPoints.tablet.min}) {
   font-size: ${props => props.theme.font.sizes.l};
  }
}

.headline-3 {
  font-family: materialBold;
  font-size: ${props => props.theme.font.sizes.l};
  color: ${props => props.theme.light.colors.primaryBlue};
  text-transform: capitalize;
}

.text-1 {
  font-family: materialRegular;
  font-size: ${props => props.theme.font.sizes.xxs};
  color: ${props => props.theme.light.colors.darkGrey};
}

.text-2 {
  font-family: materialRegular;
  font-size: ${props => props.theme.font.sizes.s};
  color: ${props => props.theme.light.colors.darkGrey};
}

.text-3 {
  font-family: materialMedium;
  font-size: ${props => props.theme.font.sizes.l};
  color: ${props => props.theme.light.colors.black};
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a,
a:hover,
a:focus,
a:active,
a.active {
  text-decoration: none;
  color: unset;
}

button:not(:disabled) {
    cursor: pointer;
}

.headline-title{
  color: #1E4164 !important;
  font-size: 24px !important;
  text-transform: capitalize;
  margin-bottom: 8px;
  font-family: materialBold;
  
  @media screen and (max-width: ${props => props.theme.breakPoints.tablet.max}){
    font-size: ${props => props.theme.font.sizes.l} !important;
  }
}

.entity-title{
  margin-bottom: 8px;
  font-size: ${props => props.theme.font.sizes.xxxl} !important;
  font-family: materialBold;
  @media screen and (max-width: ${props => props.theme.breakPoints.tablet.max}){
    font-size: ${props => props.theme.font.sizes.l} !important;
  }
}

.headline-subtitle{
  font-size: ${props => props.theme.font.sizes.l};
  color: ${props => props.theme.light.colors.black};
  font-family: materialRegular;
  @media screen and (max-width: ${props => props.theme.breakPoints.tablet.max}){
    font-size: ${props => props.theme.font.sizes.s};
  }
}

.hide-scrollbar {
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.toolbar{
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  @media screen and (max-width: ${props => props.theme.breakPoints.mobile.max}){
    flex-direction: column-reverse;
    padding: 0 15px;
    min-height: 150px;
  justify-content: flex-end;
  }
}

.fslightbox-container {
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);

  .fslightbox-toolbar {
    background: transparent;
    inset-inline-end: 0;
    inset-inline-start: auto;
     .fslightbox-toolbar-button:first-child {
        pointer-events: none;
        display: none;
      } 
  }
    
  .fslightbox-slide-number-container {
    font-family: materialMedium;
  }
  
  .fslightbox-slide-btn {
    background: rgba(0, 0, 0, 0.8);
  }

  img {
    width: 100% !important;
    object-fit: contain;
  }
  .fslightbox-toolbar-button:nth-child(4) {
    display: none;
  }
  .fslightbox-toolbar-button:nth-child(5) {
    display: none;
  }
    
}
.mapboxgl-ctrl-group{
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.mapboxgl-map .mapboxgl-popup-content {
  font-size: 16px;
  text-align: center;
  color: #767676;
  background: #f2f2f2;
  border-radius: 16px;
  max-width: 200px;
  margin: 0 auto;
  padding: 8px;
}

.mapboxgl-control-container{
  position: absolute;
  inset-inline-end: 20px;
  bottom: 50px;
}

.mapboxgl-ctrl-attrib-inner{
  display: none;
}

.toaster-container {
  bottom: 0% !important;
  z-index: 9999999999999 !important;
  border-radius: 8px !important;
}

img {
  aspect-ratio: 16/9 !important;
}

input, textarea {
  font-size: 16px !important;

  ::placeholder {
    color: #666666 !important;
  }
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.unique-id {
        width: fit-content;
        font-size: 6px;
        visibility: hidden;
        height: 1px;
}

/* This styles the scrollbar track (background) */
::-webkit-scrollbar {
  width: 10px; /* Adjust the width of the scrollbar */
  background-color: transparent; /* Light grey background */
}

/* This styles the scrollbar thumb (the draggable element) */
::-webkit-scrollbar-thumb {
  background-color: #c1c1c1; /* Darker grey thumb */
  border-radius: 8px; /* Rounded corners on the thumb */
  border: 3px solid transparent; /* Optional: Adds some space between the track and thumb */
}

/* This styles the scrollbar corner (where horizontal and vertical scrollbars meet) */
::-webkit-scrollbar-corner {
  background-color: #f0f0f0; /* Matching the track color */
}

/* Optional: Style the scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #a8a8a8; /* Slightly darker thumb on hover */
}

`;

export default GlobalStyles;
