import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import styled, { ThemeContext, css, keyframes } from 'styled-components';
import Button from '../../../../../new-components/ui/Button';
import ButtonIcon from '../../../../../new-components/ui/ButtonIcon';
import CloseIcon from '../../../../../new-components/svg-icons/CloseIcon';
import FilterIcon from '../../../../../new-components/svg-icons/FilterIcon';
import { SearchFiltersActionTypes } from '../../../../../reducers/search-filters/actions';
import { SearchFiltersTypes } from '../../../../../reducers/search-filters/reducer';
import {
  SearchContext,
  SearchContextInterface
} from '../../../context/SearchContext';
import FilterContainer from '../FilterContainer';
import CheckBoxContainer from '../../search-filters/checkbox-view/CheckboxContainer';
import MultiSelectContainer from '../../search-filters/multi-select-view/MultiSelectContainer';
import SingleSelectContainer from '../../search-filters/single-select-view/SingleSelectContainer';
import SingleSelectPresentaion from '../../search-filters/single-select-view/SingleSelectPresentation';
import SliderContainer from '../../search-filters/slider-view/SliderContainer';
import MinMaxInputContainer from '../../search-filters/min-max-view/MinMaxInputContainer';
import SingleInputContainer from '../../search-filters/single-input-view/SingleInputContainer';
import ToggleFilterContainer from '../../search-filters/toggle-view/ToggleFilterContainer';
import { resetFilters } from '../../../../../helpers/search-filters';
import { logEvent } from '../../../../../new-lib/analytics';
import LocationSearchFilter from '../../search-filters/location-search/LocationSearch';
import FinancingFilter from '../../search-filters/financing-filter/FinancingFilter';
import { useSelector } from 'react-redux';
import { isEqual } from '../../../../../helpers/isEqual';

const MobileFilterMenu = () => {
  const { t } = useTranslation('search');
  const {
    showFilters,
    setShowFilters,
    filterOptions,
    searchFiltersState,
    searchFiltersDispatch
  } = useContext(SearchContext) as SearchContextInterface;
  const searchBodyResults = useSelector(
    (state: any) => state.searchResults.searchResults,
    isEqual
  );
  const theme = useContext(ThemeContext);
  const router = useRouter();
  const priceRef = useRef(null);
  const paymentRef = useRef(null);
  const areaRef = useRef(null);
  const unit_area_list = [
    { value: 50, options: { displayedValue: '50' } },
    { value: 80, options: { displayedValue: '80' } },
    { value: 100, options: { displayedValue: '100' } },
    { value: 150, options: { displayedValue: '150' } },
    { value: 200, options: { displayedValue: '200' } },
    { value: 250, options: { displayedValue: '250' } },
    { value: 300, options: { displayedValue: '300' } },
    { value: 350, options: { displayedValue: '350' } },
    { value: 400, options: { displayedValue: '400' } }
  ];
  const isNawyNowPage = router.asPath.includes('/nawy-now');

  const amenities = [
    {
      filterType: SearchFiltersTypes.HAS_GARDEN,
      value: 'has_garden',
      options: { displayedValue: t('garden') }
    },
    {
      filterType: SearchFiltersTypes.HAS_ROOF,
      value: 'has_roof',
      options: { displayedValue: t('roof') }
    }
  ];
  const [isMoveNowFiltered, setIsMoveNowFiltered] = useState<boolean>(false);

  const hideModal = () => {
    setTimeout(() => setShowFilters(!showFilters), 0);
    logEvent.search(
      'filters_close',
      'MobileFiltersMenu',
      'filter',
      searchFiltersState
    );
  };

  const isSearchResult = searchBodyResults.loadedSearchResultsCount > 0;
  const getFilterBtnText = () => {
    return searchBodyResults.isLoading
      ? '. . .'
      : isSearchResult
      ? `${t('show')} ${searchBodyResults.loadedSearchResultsCount} ${t(
          'results'
        )}`
      : t('no_results_found');
  };
  const maxDeliveryYear = () => {
    let lastDeliveryDateIndex = filterOptions.delivery_date.length - 1;

    let maxDeliveryYear = new Date(
      filterOptions.delivery_date[lastDeliveryDateIndex].value as any
    ).getFullYear();
    return maxDeliveryYear;
  };
  useEffect(() => {
    if (searchFiltersState.searchBody.nawy_now) {
      setIsMoveNowFiltered(true);
    } else {
      setIsMoveNowFiltered(false);
    }
  }, [searchFiltersState.searchBody.nawy_now]);

  return showFilters && !router.asPath.includes('/sahel-map') ? (
    <>
      <FilterModal className="modal-open">
        <ModalHeader>
          <FilterIcon />
          <h1 className="headline-2">{t('filter_by')}</h1>
          <ButtonIcon
            backgroundColor="#F3F3F3"
            backgroundHover="none"
            border="none"
            color="#646464"
            hoverColor="none"
            icon={<CloseIcon />}
            onClick={hideModal}
            dataTest="close-menu"
          />
        </ModalHeader>
        <div className="filters-container">
          {!router.asPath.includes('/compound') && (
            <FilterContainer name={t('find')}>
              <ToggleFilterContainer
                options={filterOptions.category}
                filterType={SearchFiltersTypes.CATEGORY}
              />
            </FilterContainer>
          )}
          {router.asPath.includes('/nawy-now') && (
            <FilterContainer name={t('location')}>
              <LocationSearchFilter />
            </FilterContainer>
          )}

          <FilterContainer name={t('property_types')}>
            <SliderContainer
              searchFiltersState={searchFiltersState}
              searchFiltersDispatch={searchFiltersDispatch}
              filterName={SearchFiltersTypes.PROPERTY_TYPES}
              filterTypes={filterOptions[SearchFiltersTypes.PROPERTY_TYPES]}
              options={searchBodyResults.loadedPropertyTypes}
              withCountDisplay={true}
              sliderLocation="MobileFiltersMenu"
            />
          </FilterContainer>

          <FilterContainer name={t('property_features')}>
            <CheckBoxContainer options={amenities} />
          </FilterContainer>

          <FilterContainer name={t('bedrooms')}>
            <MultiSelectContainer
              filterType={SearchFiltersTypes.BEDROOMS}
              maxDisplayedValue={5}
              shapeClass="circleShape"
            />
          </FilterContainer>

          <FilterContainer name={t('bathrooms')}>
            <MultiSelectContainer
              filterType={SearchFiltersTypes.BATHROOMS}
              maxDisplayedValue={5}
              shapeClass="circleShape"
            />
          </FilterContainer>

          <FilterContainer
            name={t('payments')}
            containerRef={paymentRef}
            displayedValues={[
              {
                name: SearchFiltersTypes.DOWN_PAYMENT,
                value: searchFiltersState.searchFilters.down_payment,
                preValue: '',
                postValue: t('DP'),
                filterAction:
                  SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE
              },
              {
                name: SearchFiltersTypes.MONTHLY_INSTALLMENTS,
                value: searchFiltersState.searchFilters.monthly_installments,
                preValue: '',
                postValue: t('monthly'),
                filterAction:
                  SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE
              },
              {
                name: SearchFiltersTypes.INSTALLMENT_YEARS,
                value:
                  searchFiltersState.searchFilters.installment_years.length > 0
                    ? searchFiltersState.searchFilters.installment_years
                    : undefined,
                preValue: '',
                postValue: t('years'),
                filterAction:
                  SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT
              }
            ]}
          >
            <>
              <SingleInputContainer
                filterType={SearchFiltersTypes.DOWN_PAYMENT}
                headline={t('down_payment')}
                curruncy={t('egp')}
                placeholder={t('max_amount')}
                min={parseInt(filterOptions.down_payment[0].value.toString())}
              />
              <SingleInputContainer
                filterType={SearchFiltersTypes.MONTHLY_INSTALLMENTS}
                headline={t('monthly_installments')}
                curruncy={t('egp')}
                min={parseInt(
                  filterOptions.monthly_installments[0].value.toString()
                )}
                placeholder={t('max_amount')}
              />
              <h3 className="text-3" style={{ marginBottom: '6px' }}>
                {t('years_of_installments')}
              </h3>
              <MultiSelectContainer
                filterType={SearchFiltersTypes.INSTALLMENT_YEARS}
                maxDisplayedValue={6}
                shapeClass="circleShape"
              />
            </>
          </FilterContainer>

          <FilterContainer
            name={t('price_range')}
            subText={`(${t('egp')})`}
            containerRef={priceRef}
            displayedValues={[
              {
                name: SearchFiltersTypes.MIN_PRICE,
                value: searchFiltersState.searchFilters.min_price,
                preValue: t('min'),
                postValue: '',
                filterAction:
                  SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE
              },
              {
                name: SearchFiltersTypes.MAX_PRICE,
                value: searchFiltersState.searchFilters.max_price,
                preValue: t('max'),
                postValue: '',
                filterAction:
                  SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE
              }
            ]}
          >
            <MinMaxInputContainer
              maxFilterType={SearchFiltersTypes.MAX_PRICE}
              minFilterType={SearchFiltersTypes.MIN_PRICE}
              minList={filterOptions.min_price}
              maxList={filterOptions.max_price}
              min={parseInt(filterOptions.min_price[0].value.toString())}
            />
          </FilterContainer>

          {!isNawyNowPage && (
            <FilterContainer name={t('delivery_date')}>
              <SingleSelectContainer
                filterType={SearchFiltersTypes.DELIVERY_DATE}
                key={'Delivery Date'}
                shapeClass="straightShape"
                Component={SingleSelectPresentaion}
                maxDisplayedValue={maxDeliveryYear()}
                isMoveNowFiltered={isMoveNowFiltered}
              />
            </FilterContainer>
          )}
          <FilterContainer
            containerRef={areaRef}
            name={t('square_meter')}
            subText={`(${t('m2')})`}
            displayedValues={[
              {
                name: SearchFiltersTypes.MIN_UNIT_AREA,
                value: searchFiltersState.searchFilters.min_unit_area,
                preValue: t('min'),
                postValue: '',
                filterAction:
                  SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE
              },
              {
                name: SearchFiltersTypes.MAX_UNIT_AREA,
                value: searchFiltersState.searchFilters.max_unit_area,
                preValue: t('max'),
                postValue: '',
                filterAction:
                  SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE
              }
            ]}
          >
            <MinMaxInputContainer
              maxFilterType={SearchFiltersTypes.MAX_UNIT_AREA}
              minFilterType={SearchFiltersTypes.MIN_UNIT_AREA}
              minList={unit_area_list}
              maxList={unit_area_list}
              min={parseInt(filterOptions.min_unit_area[0].value.toString())}
            />
          </FilterContainer>

          <FilterContainer name={t('finishing')}>
            <MultiSelectContainer
              filterType={SearchFiltersTypes.COMPLETION_TYPE}
              key={'Completion Type'}
              shapeClass="straightShape"
            />
          </FilterContainer>
          {!isNawyNowPage && (
            <>
              <FilterContainer name={t('sale_type')}>
                <MultiSelectContainer
                  filterType={SearchFiltersTypes.SALE_TYPE}
                  key={'Sale Type'}
                  shapeClass="straightShape"
                />
              </FilterContainer>
              <FinancingFilter />
            </>
          )}
        </div>
      </FilterModal>
      <ActionsContainer>
        <span
          className="reset"
          onClick={() => {
            resetFilters(
              router,
              searchFiltersDispatch,
              router.asPath.includes('/buy') ? false : true
            );
            logEvent.search(
              'filter_reset',
              'MobileFiltersMenu',
              'filter',
              searchFiltersState
            );
          }}
        >
          {t('reset_all')}
        </span>
        <Button
          backgroundColor={
            isSearchResult
              ? theme.light.colors.primaryBlue
              : theme.light.colors.grey
          }
          border="none"
          padding="8px 0"
          color={theme.light.colors.white}
          fontSize={theme.font.sizes.l}
          text={getFilterBtnText()}
          backgroundHover="none"
          hoverColor="none"
          onClick={() => {
            setShowFilters(false);
            logEvent.search(
              'filter_show_results',
              'MobileFiltersMenu',
              'filter',
              searchFiltersState
            );
          }}
          disabled={!isSearchResult}
        />
      </ActionsContainer>
    </>
  ) : null;
};

// Animation for LTR layout
const slideInFromRight = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

// Animation for RTL layout
const slideInFromLeft = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const FilterModal = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  padding: 20px;
  z-index: 999999;
  overflow: hidden;
  opacity: 0;
  ${({ dir }) =>
    dir === 'ar'
      ? css`
          left: 0;
          animation: ${slideInFromLeft} 0.2s ease forwards;
        `
      : css`
          left: 0;
          animation: ${slideInFromRight} 0.2s ease forwards;
        `}
  will-change: transform, opacity;
  will-change: transform, opacity;

  .filter-wrapper {
    .nawy-now {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .filter-info {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }

  .filters-container {
    height: calc(100dvh - 193px);
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none;
    }

    @media screen and (max-width: ${props =>
        props.theme.breakPoints.tablet.max}) {
      padding-bottom: 90px;
    }
  }
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 14px;
  & > svg {
    margin-inline-end: 8px;
    width: 22px;
    height: auto;
    path {
      fill: ${props => props.theme.light.colors.primaryBlue};
    }
  }
  h1 {
    margin-inline-end: auto;
  }
  button {
    border-radius: 3px;
    width: 35px;
    height: 35px;
    svg {
      width: 15.5px;
      height: 18.5px;
    }
  }
`;

const ActionsContainer = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px 8px 0px 0px;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 14px;
  span {
    font-family: materialMedium;
    font-size: ${props => props.theme.font.sizes.l};
    text-decoration: underline;
    margin-bottom: 16px;
  }

  @media screen and (max-width: ${props =>
      props.theme.breakPoints.tablet.min}) {
    flex-direction: column;
    gap: 8px;
    min-height: 80px;
    span {
      margin: 0;
    }
    button {
      width: calc(100vw - 40px);
      height: 45px;
      border-radius: 8px;
    }
  }
`;

export default MobileFilterMenu;
