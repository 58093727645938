interface IconProps {
  color?: string;
}
const FilterIcon = ({ color }: IconProps) => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.1676 3.66662H2.99147C3.13455 4.19307 3.44689 4.65782 3.88029 4.98915C4.3137 5.32049 4.84409 5.5 5.38964 5.5C5.93519 5.5 6.46558 5.32049 6.89898 4.98915C7.33239 4.65782 7.64472 4.19307 7.78781 3.66662H15.8332C16.01 3.66662 16.1796 3.59639 16.3046 3.47137C16.4296 3.34636 16.4999 3.1768 16.4999 3C16.4999 2.8232 16.4296 2.65364 16.3046 2.52863C16.1796 2.40361 16.01 2.33338 15.8332 2.33338H7.78781C7.64472 1.80693 7.33239 1.34219 6.89898 1.01085C6.46558 0.679515 5.93519 0.5 5.38964 0.5C4.84409 0.5 4.3137 0.679515 3.88029 1.01085C3.44689 1.34219 3.13455 1.80693 2.99147 2.33338H1.1676C0.990798 2.33338 0.821241 2.40361 0.696225 2.52863C0.57121 2.65364 0.500977 2.8232 0.500977 3C0.500977 3.1768 0.57121 3.34636 0.696225 3.47137C0.821241 3.59639 0.990798 3.66662 1.1676 3.66662ZM5.38931 1.83341C5.62003 1.83341 5.84558 1.90183 6.03743 2.03002C6.22927 2.15821 6.37879 2.3404 6.46709 2.55357C6.55539 2.76673 6.57849 3.00129 6.53348 3.22759C6.48846 3.45389 6.37736 3.66175 6.21421 3.8249C6.05106 3.98805 5.84319 4.09916 5.61689 4.14417C5.3906 4.18918 5.15604 4.16608 4.94287 4.07779C4.72971 3.98949 4.54751 3.83997 4.41932 3.64812C4.29114 3.45628 4.22272 3.23073 4.22272 3C4.22307 2.69071 4.34609 2.39419 4.56479 2.17549C4.78349 1.95679 5.08002 1.83377 5.38931 1.83341Z"
        fill={color || '#374957'}
      />
      <path
        d="M15.8293 8.33355H14.0059C13.8631 7.80712 13.551 7.34233 13.1177 7.01094C12.6844 6.67955 12.1541 6.5 11.6087 6.5C11.0632 6.5 10.5329 6.67955 10.0997 7.01094C9.6664 7.34233 9.35427 7.80712 9.21146 8.33355H1.16743C0.990672 8.33355 0.821158 8.40377 0.696175 8.52875C0.571192 8.65373 0.500977 8.82325 0.500977 9C0.500977 9.17675 0.571192 9.34627 0.696175 9.47125C0.821158 9.59623 0.990672 9.66645 1.16743 9.66645H9.21146C9.35427 10.1929 9.6664 10.6577 10.0997 10.9891C10.5329 11.3204 11.0632 11.5 11.6087 11.5C12.1541 11.5 12.6844 11.3204 13.1177 10.9891C13.551 10.6577 13.8631 10.1929 14.0059 9.66645H15.8293C16.0061 9.66645 16.1756 9.59623 16.3005 9.47125C16.4255 9.34627 16.4957 9.17675 16.4957 9C16.4957 8.82325 16.4255 8.65373 16.3005 8.52875C16.1756 8.40377 16.0061 8.33355 15.8293 8.33355ZM11.6087 10.1663C11.378 10.1663 11.1525 10.0979 10.9607 9.96973C10.7689 9.84158 10.6194 9.65943 10.5312 9.44632C10.4429 9.23321 10.4198 8.99871 10.4648 8.77247C10.5098 8.54623 10.6209 8.33842 10.784 8.17531C10.9471 8.01221 11.1549 7.90113 11.3811 7.85613C11.6074 7.81113 11.8419 7.83422 12.055 7.9225C12.2681 8.01077 12.4503 8.16025 12.5784 8.35205C12.7066 8.54384 12.775 8.76933 12.775 9C12.7746 9.30921 12.6516 9.60565 12.433 9.8243C12.2143 10.0429 11.9179 10.1659 11.6087 10.1663Z"
        fill={color || '#374957'}
      />
      <path
        d="M15.8333 14.3334H7.78783C7.64475 13.8069 7.33241 13.3422 6.89901 13.0109C6.4656 12.6795 5.93521 12.5 5.38966 12.5C4.84411 12.5 4.31371 12.6795 3.88031 13.0109C3.4469 13.3422 3.13456 13.8069 2.99148 14.3334H1.1676C0.9908 14.3334 0.821242 14.4036 0.696226 14.5286C0.57121 14.6536 0.500977 14.8232 0.500977 15C0.500977 15.1768 0.57121 15.3464 0.696226 15.4714C0.821242 15.5964 0.9908 15.6666 1.1676 15.6666H2.99148C3.13456 16.1931 3.4469 16.6578 3.88031 16.9891C4.31371 17.3205 4.84411 17.5 5.38966 17.5C5.93521 17.5 6.4656 17.3205 6.89901 16.9891C7.33241 16.6578 7.64475 16.1931 7.78783 15.6666H15.8333C16.0101 15.6666 16.1797 15.5964 16.3047 15.4714C16.4297 15.3464 16.4999 15.1768 16.4999 15C16.4999 14.8232 16.4297 14.6536 16.3047 14.5286C16.1797 14.4036 16.0101 14.3334 15.8333 14.3334ZM5.38932 16.1666C5.15859 16.1666 4.93304 16.0982 4.7412 15.97C4.54936 15.8418 4.39983 15.6596 4.31153 15.4464C4.22324 15.2333 4.20013 14.9987 4.24515 14.7724C4.29016 14.5461 4.40127 14.3382 4.56442 14.1751C4.72757 14.0119 4.93544 13.9008 5.16173 13.8558C5.38803 13.8108 5.62259 13.8339 5.83576 13.9222C6.04892 14.0105 6.23112 14.16 6.35931 14.3519C6.48749 14.5437 6.55591 14.7693 6.55591 15C6.55538 15.3092 6.43231 15.6057 6.21364 15.8243C5.99498 16.043 5.69856 16.1661 5.38932 16.1666Z"
        fill={color || '#374957'}
      />
    </svg>
  );
};

export default FilterIcon;
