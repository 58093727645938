import styled, { keyframes } from 'styled-components';

const LinearProgress = () => {
  return <Container />;
};

const loadingAnimation = keyframes`
  from { transform: translateX(-100%); }
  to { transform: translateX(100%); }
`;

const Container = styled.div`
  width: 100%;
  height: 4px;
  margin-bottom: 10px;
  background-color: #e0e0e0;
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    background-image: linear-gradient(to right, #1e4164, #36688d 50%, #1e4164);
    animation: ${loadingAnimation} 1s linear infinite;
  }
`;

export default LinearProgress;
