import styled from 'styled-components';
import SearchSelections, { SearchSelection } from './SearchSelections';
import SearchLocationResults, {
  SearchInputResult
} from './SearchLocationResults';
import LinearProgress from '../../../../new-components/LinearProgress';
import { Dispatch, SetStateAction, useEffect } from 'react';

interface DropdownProps {
  selectedSearches: SearchSelection[];
  clearSearch: () => void;
  handleRemove: (selection: SearchSelection) => void;
  searchResults: SearchInputResult[];
  isLoading?: boolean;
  isError?: boolean;
  handleSelect: (selection: SearchInputResult) => void;
  dropdownRef?: any;
  setLoader: Dispatch<SetStateAction<boolean>>;
}

const SearchDropdown = ({
  setLoader,
  selectedSearches,
  clearSearch,
  handleRemove,
  searchResults,
  isLoading,
  isError,
  handleSelect,
  dropdownRef
}: DropdownProps) => {
  useEffect(() => {
    setLoader(false);
  }, []);
  return (
    <Dropdown
      className="container-fluid search-dropdown"
      ref={dropdownRef}
      data-test="search-input-dropdown"
    >
      <div className="loader">{isLoading && <LinearProgress />}</div>

      {selectedSearches.length > 0 && (
        <SearchSelections
          clearClick={clearSearch}
          selectedSearches={selectedSearches}
          handleRemove={handleRemove}
        />
      )}

      {!isError && (
        <SearchLocationResults
          searchResults={searchResults}
          handleSelect={handleSelect}
        />
      )}
    </Dropdown>
  );
};

const Dropdown = styled.div`
  background-color: ${props => props.theme.light.backgroundColor};
  width: 100%;
  overflow-y: auto;
  border-radius: 10px;
  position: absolute;
  top: 50px;
  padding: 16px;
  max-height: 360px;
  box-shadow: 0px 4px 10px 0px #00000026;
  z-index: 99;

  .loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999999999999;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: ${props =>
      props.theme.breakPoints.mobile.max}) {
    height: calc(100vh - 130px);
    position: fixed;
    top: 130px;
    left: 0%;
    max-height: unset;
    border-radius: 0;
    box-shadow: none;
  }
`;

export default SearchDropdown;
